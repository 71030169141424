import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {ErrorMessage} from '@hookform/error-message';
import PrivacyPolicyModal from '../../PrivacyPolicyModal/PrivacyPolicyModal';
import Input from 'react-phone-number-input/input';
import LoaderButtonFormService from '../../../common/Button/loaderButtonFormService/LoaderButtonFormService';
import authOperations from '../../../features/auth/authOperations';
import authSlice from '../../../features/auth/authSlice';
import authSelectors from '../../../features/auth/authSelectors';
import {emailPattern, phonePattern} from '../../../pattern/formPattern';

import LoaderColor from '../../../common/Button/enum/LoaderColor';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye} from '@fortawesome/free-solid-svg-icons';

import authWithPhoneStyles from './AuthenticationWithPhone.module.scss';

const eye = <FontAwesomeIcon icon={faEye} />;

interface Props {
    toggleModal: Dispatch<SetStateAction<boolean>>;
    isModalOpen: boolean;
}

export enum Label {
    Login = 'Login',
    Forgot = 'Forgot',
    SignUp = 'SignUp'
}

const AuthenticationWithPhone: React.FC<Props> = ({toggleModal, isModalOpen}) => {

    const {t} = useTranslation();

    const [inputs, setInputs] = useState({
        phone: '+380',
        name: '',
        email: '',
        code: '',
        password: '',
        isPrivacyPolicyAccepted: false,
        repeatPassword: '',
    });

    const [label, setLabel] = useState(Label.Login);

    useEffect(() => {
        setLabel(Label.Login);
    }, [isModalOpen]);

    const {phone, name, email, repeatPassword, password, isPrivacyPolicyAccepted} = inputs;

    const {
        register,
        formState: {errors},
        handleSubmit,
        clearErrors,
        reset,
        watch,
        setError,
    } = useForm();

    const dispatch = useDispatch();

    const [isPrivacyPolicyModalOpen, togglesPrivacyPolicyModal] = useState(false);

    useEffect(() => {
        dispatch(authSlice.actions.setHeaderByMobileVersion(label));
    }, [label]);

    const cleaning = () => {
        setInputs({
            phone: '+380',
            name: '',
            code: '',
            email: '',
            password: '',
            isPrivacyPolicyAccepted: false,
            repeatPassword: '',
        });
        clearErrors();
        reset();
    };

    const enterEmailCodeModal = useSelector(authSelectors.enterEmailCodeModal);

    useEffect(() => {
        enterEmailCodeModal && setLabel(Label.Forgot);
    }, []);

    useEffect(() => {
        return () => {
            setLabel(Label.Login);
        };
    }, []);

    useEffect(() => {
        if (!isModalOpen) {
            cleaning();
        }
    }, [isModalOpen]);

    const changeHandler = (e: any) => {
        const {name: inputName, value} = e.target;
        setInputs({...inputs, [inputName]: value});

        if (
            phonePattern.test(
                e.target.value,
            )
        ) {
            clearErrors('phone');
        }
    };

    useEffect(() => {
        return () => {
            clearErrors('password');
        };
    }, []);

    const isLoggedIn: boolean = useSelector(authSelectors.getIsAuthenticated);

    useEffect(() => {
        isLoggedIn && toggleModal(false);
    }, [isLoggedIn]);

    const submitHandler = async () => {
        dispatch(authSlice.actions.setPhone(inputs.phone));
        dispatch(authSlice.actions.setPassword(inputs.password));
        dispatch(authSlice.actions.setEmail(inputs.email));
        switch (label) {
            case Label.SignUp:
                dispatch(authSlice.actions.setName(inputs.name));
                await dispatch(authOperations.register({
                    name,
                    phone,
                    email,
                    password,
                    privacyPolicyAccepted: isPrivacyPolicyAccepted,
                }));
                toggleModal(false);
                break;

            case Label.Login:
                await dispatch(authOperations.logIn({
                    login: email,
                    password,
                }));
                // await dispatch(paymentOperation.getBalance());
                break;
            default:
                await dispatch(authOperations.forgotSecurityCodeEmail(email));
                break;
        }
    };

    const isNotCorrectPassword = useSelector(authSelectors.getIsNotCorrectPassword);

    useEffect(() => {
        if (isNotCorrectPassword) {
            setError('password', {
                type: 'manual',
                message: t('formErrors.invalidPass'),
            });
            dispatch(authSlice.actions.isNotCorrectPasswordFalse(false));
        }
    }, [isNotCorrectPassword]);

    const labelSwitch = (lbl: string) => {
        switch (lbl) {
            case Label.Login:
                return t('auth.withPhone.message.label.Login');
            case Label.Forgot:
                return t('auth.withPhone.message.label.Forgot');
            case Label.SignUp:
                return t('auth.withPhone.message.label.SignUp');
        }
    };

    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordRepeatShown, setPasswordRepeatShown] = useState(false);

    const togglePasswordVisibility = (str: string) => {
        if (str === 'password') {
            setPasswordShown(!passwordShown);
        } else if (str === 'repeatPassword') setPasswordRepeatShown(!passwordRepeatShown);
    };

    const isPrivacyPolicy = watch('isPrivacyPolicyAccepted');

    return (
        <div className={authWithPhoneStyles.auth_form_main}>
            <h4 className={authWithPhoneStyles.auth_tittle}>{t('auth.withPhone.message.title')}</h4>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmit(submitHandler);
                }}
                className={label === Label.SignUp && authWithPhoneStyles.form__scroll}
            >
                <div onChange={changeHandler} className={authWithPhoneStyles.auth_form_main__container}>
                    {label !== Label.Forgot && label !== Label.Login ?
                        <>
                            <p className={authWithPhoneStyles.auth_label}>{t('auth.withPhone.phone')}*</p>
                            <div className={authWithPhoneStyles.pass_wrapper}>
                                <Input
                                    {...register('phone', {
                                        pattern: {
                                            value: phonePattern,
                                            message: t('formErrors.pattern'),
                                        },
                                        required: t('formErrors.required').toString(),
                                        validate: () => {
                                            if (isNotCorrectPassword) {
                                                return t('formErrors.invalidPass').toString();
                                            }
                                        },
                                    })}
                                    className={
                                        errors.phone
                                            ? authWithPhoneStyles.input_field__error
                                            : authWithPhoneStyles.input_field
                                    }
                                    placeholder="+380 99 333 3333"
                                    value={phone}
                                    name="phone"
                                    onChange={() => changeHandler}
                                />
                            </div>
                            <ErrorMessage
                                errors={errors}
                                name="phone"
                                render={({message}) => <p className="error-message">{message}</p>}
                            />
                        </>
                        :
                        <>
                            <p className={authWithPhoneStyles.auth_label}>{t('auth.withPhone.email')}*</p>
                            <div className={authWithPhoneStyles.pass_wrapper}>
                                <input
                                    {...register('email', {
                                        required: t('formErrors.required').toString(),
                                        pattern: {
                                            value: emailPattern,
                                            message: t('formErrors.invalidEmail'),
                                        },
                                        minLength: {
                                            value: 2,
                                            message: t('formErrors.minLength'),
                                        },
                                    })}
                                    name="email"
                                    type="text"
                                    value={email}
                                    className={
                                        errors.email
                                            ? authWithPhoneStyles.input_field__error
                                            : authWithPhoneStyles.input_field
                                    }
                                />
                            </div>
                            {errors.email ? (
                                <p className="error-message">
                                    {errors.email.message}
                                </p>
                            ) : (<p></p>)}
                        </>}

                    {label === Label.Login ? (
                        <div>
                            <p className={authWithPhoneStyles.auth_label}>{t('auth.withPhone.pass')}*</p>
                            <div className="pass-wrapper">
                                <input
                                    {...register('password', {
                                        required: t('formErrors.required').toString(),
                                        minLength: {
                                            value: 6,
                                            message: t('formErrors.minLength'),
                                        },
                                        onChange: () => {
                                            dispatch(authSlice.actions.isNotCorrectPasswordFalse(false));
                                            clearErrors('password');
                                        },
                                    })}
                                    name="password"
                                    autoComplete="on"
                                    type="password"
                                    value={password}
                                    className={
                                        errors.password
                                            ? authWithPhoneStyles.input_field__error
                                            : authWithPhoneStyles.input_field
                                    }
                                />
                            </div>
                            {
                                errors.password ? (
                                    <p className="error-message">
                                        {errors.password.message}
                                    </p>
                                ) : (
                                    <p></p>
                                )
                            }
                            <p onClick={() => {
                                cleaning();
                                setLabel(Label.Forgot);
                            }}
                               className={authWithPhoneStyles.forgot}>
                                {t('auth.withPhone.question')}
                            </p>
                        </div>
                    ) : (<></>)}

                    {label === Label.Forgot ? (
                        <>
                            <div>
                                <p className={authWithPhoneStyles.auth_label}>{t('auth.withPhone.forgotPass')}*</p>
                                <div className={authWithPhoneStyles.pass_wrapper}>
                                    <input
                                        {...register('password', {
                                            required: t('formErrors.required').toString(),
                                            minLength: {
                                                value: 6,
                                                message: t('formErrors.minLength'),
                                            },
                                        })}
                                        name="password"
                                        type={passwordShown ? 'text' : 'password'}
                                        value={password}
                                        autoComplete="on"
                                        className={
                                            errors.password
                                                ? authWithPhoneStyles.input_field__error
                                                : authWithPhoneStyles.input_field
                                        }
                                    />
                                    <i onClick={() => togglePasswordVisibility('password')}
                                       className={authWithPhoneStyles.eye}>{eye}</i>
                                </div>
                            </div>
                            {errors.password ? (
                                <p className="error-message">
                                    {errors.password.message}
                                </p>
                            ) : (<p></p>)}
                            <div className="mt-3">
                                <p className={authWithPhoneStyles.auth_label}>{t('auth.withPhone.repeatPass')}*</p>
                                <div className={authWithPhoneStyles.pass_wrapper}>
                                    <input
                                        {...register('repeatPassword', {
                                            required: t('formErrors.required').toString(),
                                            minLength: {
                                                value: 6,
                                                message: t('formErrors.minLength'),
                                            },
                                            validate: (val: string) => {
                                                if (watch('password') !== val) {
                                                    return t('formErrors.doNotMatch').toString();
                                                }
                                            },
                                        })}
                                        name="repeatPassword"
                                        autoComplete="on"
                                        type={passwordRepeatShown ? 'text' : 'password'}
                                        value={repeatPassword}
                                        className={
                                            errors.repeatPassword
                                                ? authWithPhoneStyles.input_field__error
                                                : authWithPhoneStyles.input_field
                                        }
                                    />
                                    <i onClick={() => togglePasswordVisibility('repeatPassword')}
                                       className={authWithPhoneStyles.eye}>{eye}</i>
                                </div>
                            </div>
                            {errors.repeatPassword ? (
                                <p className="error-message">
                                    {errors.repeatPassword.message}
                                </p>
                            ) : (<p></p>)}
                        </>
                    ) : (
                        <></>)}

                    {label === Label.SignUp ? (
                        <>
                            <div>
                                <p className={authWithPhoneStyles.auth_label}>{t('auth.withPhone.name')}*</p>
                                <div className={authWithPhoneStyles.pass_wrapper}>
                                    <input
                                        {...register('name', {
                                            required: t('formErrors.required').toString(),
                                            minLength: {
                                                value: 2,
                                                message: t('formErrors.minLength'),
                                            },
                                        })}
                                        name="name"
                                        type="text"
                                        value={name}
                                        className={
                                            errors.name
                                                ? authWithPhoneStyles.input_field__error
                                                : authWithPhoneStyles.input_field
                                        }
                                    />
                                </div>
                                <ErrorMessage
                                    errors={errors}
                                    name="name"
                                    render={({message}) => (
                                        <p className="error-message">{message}</p>
                                    )}
                                />
                            </div>
                            <div>
                                <p className={authWithPhoneStyles.auth_label}>{t('auth.withPhone.email')}*</p>
                                <div className={authWithPhoneStyles.pass_wrapper}>
                                    <input
                                        {...register('email', {
                                            required: t('formErrors.required').toString(),
                                            pattern: {
                                                value: emailPattern,
                                                message: t('formErrors.invalidEmail'),
                                            },
                                            minLength: {
                                                value: 2,
                                                message: t('formErrors.minLength'),
                                            },
                                        })}
                                        name="email"
                                        type="text"
                                        value={email}
                                        className={
                                            errors.email
                                                ? authWithPhoneStyles.input_field__error
                                                : authWithPhoneStyles.input_field
                                        }
                                    />
                                </div>
                                {errors.email ? (
                                    <p className="error-message">
                                        {errors.email.message}
                                    </p>
                                ) : (<p></p>)}
                            </div>
                            <div>
                                <p className={authWithPhoneStyles.auth_label}>{t('auth.withPhone.pass')}*</p>
                                <div className={authWithPhoneStyles.pass_wrapper}>
                                    <input
                                        {...register('password', {
                                            required: t('formErrors.required').toString(),
                                            minLength: {
                                                value: 6,
                                                message: t('formErrors.minLength'),
                                            },
                                        })}
                                        name="password"
                                        type={passwordShown ? 'text' : 'password'}
                                        value={password}
                                        autoComplete="on"
                                        className={
                                            errors.password
                                                ? authWithPhoneStyles.input_field__error
                                                : authWithPhoneStyles.input_field
                                        }
                                    />
                                    <i onClick={() => togglePasswordVisibility('password')}
                                       className={authWithPhoneStyles.eye}>{eye}</i>
                                </div>
                            </div>
                            {errors.password ? (
                                <p className="error-message">
                                    {errors.password.message}
                                </p>
                            ) : (<p></p>)}

                            <div className={authWithPhoneStyles.privacy_flex}>
                                <span className={authWithPhoneStyles.privacy}>{t('auth.withPhone.rules.question')} <a
                                    onClick={() => togglesPrivacyPolicyModal(!isPrivacyPolicyModalOpen)}
                                    className={authWithPhoneStyles.privacy_href}>{t('auth.withPhone.rules.rule')}</a>?</span>
                                <input
                                    {...register('isPrivacyPolicyAccepted', {
                                        required: t('formErrors.required').toString(),
                                    })}
                                    value="true"
                                    name="isPrivacyPolicyAccepted"
                                    type="checkbox"
                                    className={authWithPhoneStyles.privacy_input}
                                />
                            </div>
                        </>
                    ) : (<></>)}
                </div>

                <div className={authWithPhoneStyles.form_group}>
                    <LoaderButtonFormService buttonContent={labelSwitch(label)}
                                             buttonClassName={isPrivacyPolicy || label !== Label.SignUp ? 'authentication_btn' : 'authentication_btn_not_active'}
                                             submit={handleSubmit(submitHandler)}
                                             color={LoaderColor.White}
                                             isActive={isPrivacyPolicy}
                    />

                    {label !== Label.Forgot ?
                        <>
                            {label === Label.SignUp ? (
                                <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => {
                                        toggleModal(!isModalOpen);
                                        clearErrors();
                                    }}
                                    onClick={() => {
                                        toggleModal(true);
                                        setLabel(Label.Login);
                                        cleaning();
                                    }}
                                >
                                    <p className={authWithPhoneStyles.under_title}>
                                        {t('auth.withPhone.message.question.yes')}
                                        <br />{t('auth.withPhone.message.question.make')}
                                    </p>
                                </div>
                            ) : (
                                <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => {
                                        toggleModal(!isModalOpen);
                                        clearErrors();
                                    }}
                                    onClick={() => {
                                        toggleModal(true);
                                        setLabel(Label.SignUp);
                                        cleaning();
                                    }}
                                >
                                    <p className={authWithPhoneStyles.under_title}>
                                        {t('auth.withPhone.message.question.no')}
                                        <br />{t('auth.withPhone.message.question.create')}
                                    </p>
                                </div>
                            )}
                        </>
                        :
                        <>
                            <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => {
                                    toggleModal(!isModalOpen);
                                    clearErrors();
                                }}
                                onClick={() => {
                                    toggleModal(true);
                                    setLabel(Label.Login);
                                    cleaning();
                                }}
                            >
                                <p className={authWithPhoneStyles.under_title}>
                                    {t('auth.withPhone.message.question.forgot')}
                                </p>
                            </div>
                        </>}
                </div>
            </form>
            {isPrivacyPolicyModalOpen &&
              <PrivacyPolicyModal isModalOpen={isPrivacyPolicyModalOpen} toggleModal={togglesPrivacyPolicyModal} />}
        </div>
    );
};

export default AuthenticationWithPhone;