import {createEntityAdapter, createSlice, PayloadAction} from '@reduxjs/toolkit';
import userOperations from './userOperations';
import {OfferElementsType} from '../../models/generalTypes';
import {toast} from 'react-toastify';
import i18n from 'i18next';

export const userOffersAdapter = createEntityAdapter<OfferElementsType>();

interface UserState {
    updatedUserProfile: any,
    totalUserOffers: any,
    totalPagesUserOffers: any,
    myOffer: any;
    userProfile: any;
    isLoading: boolean;
    isCodeModalOpen: boolean;
    acceptancesCountToUserOffer: [];
    email: any,
    phone: any,
    code: any,
    userPhoto: boolean,
    userOffers: any;
    session: {
        error: any;
        isFetching: boolean;
        currentPage: string;
        pageSize: string;
        totalElements: string | null;
    };
}

const initialState: UserState = {
    updatedUserProfile: null,
    totalUserOffers: null,
    isLoading: false,
    totalPagesUserOffers: null,
    acceptancesCountToUserOffer: [],
    userPhoto: false,
    isCodeModalOpen: false,
    myOffer: [],
    userProfile: null,
    email: null,
    phone: null,
    code: null,
    userOffers: userOffersAdapter.getInitialState(),
    session: {
        error: [],
        isFetching: false,

        currentPage: '0',
        pageSize: '10',
        totalElements: null,
    },
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearUserOffers: (state, {payload}) => {
            userOffersAdapter.removeAll(state.userOffers);
        },
        clearTotalUserOffers: (state, {payload}) => {
            state.totalUserOffers = null;
        },
        clearUserProfile: {
            reducer: (state) => {
                state.userProfile = null;
            },
            prepare: (any) => {
                return {payload: any};
            },
        },
        logoutUser: {
            reducer: (state) => {
                state.userProfile = {};
                state.userOffers = [];
            },
            prepare: (any) => {
                return {payload: any};
            },
        },
        toggleCodeModal: {
            reducer: (state, action: PayloadAction<boolean>) => {
                state.isCodeModalOpen = action.payload;
            },
            prepare: (boolean: boolean) => {
                return {payload: boolean};
            },
        },
    },
    extraReducers: builder => {
        builder.addCase(
            userOperations.getUserProfile.fulfilled,
            (state, action) => {
                state.isLoading = false;
                state.userProfile = action.payload.data;
            },
        );
        builder.addCase(
            userOperations.getAcceptancesCountToUserOffer.fulfilled,
            (state, action) => {
                state.acceptancesCountToUserOffer = action.payload.data;
            },
        );
        builder.addCase(
            userOperations.reportRealtor.fulfilled,
            (state, action) => {
                toast.success(i18n.t('notification.report'));
            },
        );
        builder.addCase(
            userOperations.codeToChangeEmail.fulfilled,
            (state, action: any) => {
                state.updatedUserProfile = action.payload;
                toast.success(i18n.t('notification.profileUpdate'));
            },
        );
        builder.addCase(
            userOperations.codeToChangePhone.fulfilled,
            (state, action: any) => {
                state.updatedUserProfile = action.payload;
                toast.success(i18n.t('notification.profileUpdate'));
            },
        );
        builder.addCase(userOperations.getUserOffers.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(userOperations.getUserOffers.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(userOperations.getUserOffers.fulfilled, (state, action) => {
            userOffersAdapter.setAll(state.userOffers, action.payload.data.elements);
            state.isLoading = false;
            state.totalUserOffers = action.payload.data.totalElements;
            state.totalPagesUserOffers = action.payload.data.totalPages;
        });
        builder.addCase(userOperations.deleteOffer.fulfilled, (state, action) => {
            state.userOffers = state.userOffers.filter((offer: any) => offer.id !== action.payload);
        });
        builder.addCase(
            userOperations.setOfferIdState.fulfilled,
            (state, action) => {
                state.myOffer = action.payload;
            },
        );
        builder.addCase(
            userOperations.changeUserEmail.fulfilled,
            (state, action: any) => {
                state.email = action.payload.email;
                state.isCodeModalOpen = true;
            },
        );
        builder.addCase(
            userOperations.changeUserPhone.fulfilled,
            (state, action: any) => {
                state.phone = action.payload.phone;
                state.isCodeModalOpen = true;
            },
        );
        builder.addCase(
            userOperations.uploadUserPhoto.fulfilled,
            (state, action) => {
                state.userPhoto = true;
            },
        );
        builder.addCase(
            userOperations.updateUser.fulfilled,
            (state, action) => {
                state.updatedUserProfile = action.payload;
                toast.success(i18n.t('notification.profileUpdate'));
            },
        );
    },
});

export default userSlice;