// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App_main__content__container__9GwYN {\n  min-height: 100vh;\n  position: relative;\n  padding-bottom: 245px;\n}\n\n.App_toast_custom__98ht0 {\n  position: fixed;\n  z-index: 99999;\n  border-radius: 8px !important;\n}", "",{"version":3,"sources":["webpack://./src/components/App.module.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,kBAAA;EACA,qBCQc;ADThB;;AAIA;EACE,eAAA;EACA,cAAA;EACA,6BAAA;AADF","sourcesContent":["@import \"../styles/_variables\";\n\n.main__content__container {\n  min-height: 100vh;\n  position: relative;\n  padding-bottom: $footer-height;\n}\n\n.toast_custom {\n  position: fixed;\n  z-index: 99999;\n  border-radius: 8px !important;\n}","@import \"./_colors\";\n\n// common styles\n\n$primary-font-color: $white;\n\n\n// Home page\n\n\n\n// Footer\n\n$footer-height: 245px;\n$footer-background-color: #253040;\n$footer-primary-font-color: $white;\n$footer-secondary-font-color: #E0E0E0;\n\n\n// Scroll to top button\n\n$scroll-to-top-button-background-color: $primary-green;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main__content__container": "App_main__content__container__9GwYN",
	"toast_custom": "App_toast_custom__98ht0"
};
export default ___CSS_LOADER_EXPORT___;
