import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AcceptanceCreatorType, AcceptanceType, CreatorType} from '../../../../models/generalTypes';
import AcceptancesContactModal from '../contactModal/AcceptancesContactModal';
import {useDispatch, useSelector} from 'react-redux';
import acceptanceOperations from '../../../../features/acceptance/acceptanceOperations';
import acceptanceSelectors from '../../../../features/acceptance/acceptanceSelectors';
import acceptanceSlice from '../../../../features/acceptance/acceptanceSlice';
import LoaderButtonService from '../../../../common/Button/loaderButtonService/LoaderButtonService';
import BasicModal from '../../../BasicModal/BasicModal';

import LoaderColor from '../../../../common/Button/enum/LoaderColor';
import Status from '../../enums/Status';
import useWindowSize from '../../../../hooks/useWindowSize';
import WindowSize from '../../../../enums/WindowSize';
import PhotoSize from '../../enums/PhotoSize';

import closeButtonSmall from '../../images/closeButtonSmall.svg';
import price from '../../images/price.svg';
import addressSvg from '../../images/address.svg';
import pet from '../../images/pet.svg';
import security from '../../images/security.svg';

import './AcceptancesModalItem.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

interface Props {
    acceptance: AcceptanceType,
    address: string
}

const AcceptancesModalItem = ({acceptance, address}: Props) => {

    const [confirm, setConfirm] = useState(false);

    const [isModalOpen, toggleModal] = useState(false);

    const [isRemoveModalOpen, toggleRemoveModal] = useState(false);

    const [show, setShow] = useState(true);

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const parseIsoDate = (isoString: string | Date) => {

        const date = new Date(isoString as string);
        const year = date.getFullYear();
        let month = (date.getMonth() + 1) as string | number;
        let dt = date.getDate() as string | number;
        let hours = date.getHours() as string | number;
        let minutes = date.getMinutes() as string | number;

        if (dt < 10) {
            dt = `0${dt}`;
        }
        if (month < 10) {
            month = `0${month}`;
        }

        if (hours < 10) {
            hours = `0${hours}`;
        }

        if (minutes < 10) {
            minutes = `0${minutes}`;
        }

        return `${year}-${month}-${dt} ${hours}:${minutes}`;
    };

    const showItem = (isShow: boolean) => {
        setShow(isShow);
    };

    const acceptanceCreator: Array<AcceptanceCreatorType> = useSelector(acceptanceSelectors.getAcceptanceCreator.selectAll);
    const clear = () => dispatch(acceptanceSlice.actions.clearAcceptanceCreator(acceptanceCreator));

    const getLastPhotoAdded = useCallback(
        (creator: CreatorType) => {
            return creator.photos.filter(photo => photo.photoSize === PhotoSize.Large)[creator.photos.filter(photo => photo.photoSize === PhotoSize.Large).length - 1].photoLocation;
        },
        [acceptance.creator],
    );

    const removeAcceptance = async () => {
        await dispatch(acceptanceOperations.updateAcceptanceStatus({
            id: acceptance.id,
            status: Status.Rejected,
        }));
        dispatch(acceptanceOperations.getOffersAcceptancesCount(acceptance.offerId));
        showItem(false);
        toggleModal(!isModalOpen);
    };

    const {isScreenResolutionMobile} = useWindowSize();

    return (
        <>
            {acceptance.status !== Status.Rejected && show &&
              <div className="items" key={acceptance.id}>

                <div className="main_flex">

                    {acceptance.creator.photos.length !== 0 ?
                        <div className="items__left">
                            <img
                                className="items__left-img"
                                src={getLastPhotoAdded(acceptance.creator)}
                                alt="avatar" />
                            <p className="items__left-name">{acceptance.creator.name} {acceptance.creator.lastName}</p>
                            {isScreenResolutionMobile &&
                              <div
                                role="button"
                                onClick={() => {
                                    toggleRemoveModal(!isRemoveModalOpen);
                                }}
                                className="items__right-close"
                                id="animated"
                              >
                                <p>
                                  <img src={closeButtonSmall} alt="close" />
                                </p>
                              </div>}
                        </div>
                        :
                        <div className="items__left">
                            <img
                                className="items__left-img"
                                src="https://klike.net/uploads/posts/2019-03/1551511801_1.jpg"
                                alt="avatar" />
                            <p className="items__left-name">{acceptance.creator.name} {acceptance.creator.lastName}</p>
                        </div>}

                    {isScreenResolutionMobile && <div className="center_line"></div>}

                  <div className="items__right">
                    <div className="flex_space">
                      <div className="flex">
                        <p>
                          <img src={price} alt="price" />
                        </p>
                        <p
                          className="items__right-price">{acceptance.price} {acceptance.currency}/{t('acceptancesModal.item.currency')}</p>
                      </div>
                        {!isScreenResolutionMobile &&
                          <div
                            role="button"
                            onClick={() => {
                                toggleRemoveModal(!isRemoveModalOpen);
                            }}
                            className="items__right-close"
                            id="animated"
                          >
                            <p>
                              <img src={closeButtonSmall} alt="close" />
                            </p>
                          </div>}
                    </div>
                    <div className="flex">
                      <p>
                        <img src={addressSvg} alt="address" />
                      </p>
                      <p className="items__right-address">{address}</p>
                    </div>
                    <div className="flex flex-indent">
                        {acceptance.petDeposit !== null &&
                          <>
                            <p>
                              <img src={pet} alt="pet" />
                            </p>
                            <p className="items__right-other-price">{acceptance.petDeposit} {acceptance.currency}</p>
                          </>}
                        {acceptance.securityDeposit !== null &&
                          <>
                            <p>
                              <img src={security} alt="security" />
                            </p>
                            <p
                              className="items__right-other-price">{acceptance.securityDeposit} {acceptance.currency}</p>
                          </>}
                    </div>
                      {acceptance.comment.length !== 0 &&
                        <div
                          className={acceptance.securityDeposit !== null && acceptance.petDeposit !== null ? '' : 'mb-2'}
                        >
                          <details>
                            <summary className="items__right-comment">
                                {t('acceptancesModal.item.comment')}
                            </summary>
                            <p>
                                {acceptance.comment}
                            </p>
                          </details>
                        </div>}
                    <div
                      className="flex_space mb-1">
                      <p className="items__right-date">{parseIsoDate(acceptance.createdOn)}</p>
                        {!isScreenResolutionMobile &&
                          <>
                              {acceptance.status === Status.Approved || confirm ?
                                  <LoaderButtonService
                                      buttonClassName={'acceptance_modal_confirmed_btn'}
                                      buttonContent={t('acceptancesModal.btn.item.confirmed')}
                                      query={acceptanceOperations.getAcceptanceCreator(acceptance.id)}
                                      color={LoaderColor.Green}
                                      stateCondition={isModalOpen}
                                      setStateCondition={toggleModal}
                                  />
                                  :
                                  <LoaderButtonService
                                      buttonClassName={'acceptance_modal_base_btn'}
                                      buttonContent={t('acceptancesModal.btn.item.base')}
                                      query={acceptanceOperations.updateAcceptanceStatus({
                                          id: acceptance.id,
                                          status: Status.Approved,
                                      })}
                                      color={LoaderColor.White}
                                      stateCondition={confirm}
                                      setStateCondition={setConfirm}
                                  />}
                          </>}

                        {isModalOpen &&
                          <AcceptancesContactModal isModalOpen={isModalOpen} toggleModal={toggleModal}
                                                   creator={acceptanceCreator} clear={clear}
                                                   key={acceptance.creator.id} />}

                        {isRemoveModalOpen && <BasicModal isModalOpen={isRemoveModalOpen}
                                                          toggleModal={toggleRemoveModal}
                                                          header={t('acceptanceRemoveModal.header')}
                                                          leftButton={t('acceptanceRemoveModal.btn.cancel')}
                                                          onClick={removeAcceptance}
                                                          question={t('acceptanceRemoveModal.question')}
                                                          rightButton={t('acceptanceRemoveModal.btn.remove')} />}
                    </div>
                      {isScreenResolutionMobile &&
                        <div className="mobile_button">
                            {acceptance.status === Status.Approved || confirm ?
                                <LoaderButtonService
                                    buttonClassName={'acceptance_modal_confirmed_btn'}
                                    buttonContent={t('acceptancesModal.btn.item.confirmed')}
                                    query={acceptanceOperations.getAcceptanceCreator(acceptance.id)}
                                    color={LoaderColor.Green}
                                    stateCondition={isModalOpen}
                                    setStateCondition={toggleModal}
                                />
                                :
                                <LoaderButtonService
                                    buttonClassName={'acceptance_modal_base_btn'}
                                    buttonContent={t('acceptancesModal.btn.item.base')}
                                    query={acceptanceOperations.updateAcceptanceStatus({
                                        id: acceptance.id,
                                        status: Status.Approved,
                                    })}
                                    color={LoaderColor.White}
                                    stateCondition={confirm}
                                    setStateCondition={setConfirm}
                                />}
                        </div>}
                  </div>
                </div>
              </div>
            }
        </>
    );
};

export default AcceptancesModalItem;