// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LookingRentTabs_choice__3693e {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  padding-top: 30px;\n  gap: 20px;\n}\n@media (max-width: 768px) {\n  .LookingRentTabs_choice__3693e {\n    padding-top: 10px;\n  }\n}\n.LookingRentTabs_choice_btn__aTqiW {\n  border: none;\n  color: #253040;\n  font-size: 18px;\n  background: none;\n}\n@media (max-width: 768px) {\n  .LookingRentTabs_choice_btn__aTqiW {\n    font-size: 16px;\n  }\n}\n.LookingRentTabs_choice_btn__underline__jAouV {\n  border-bottom: 1px solid #253040;\n}", "",{"version":3,"sources":["webpack://./src/components/LookingRentTabs/LookingRentTabs.module.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,SAAA;AAFF;AAIE;EAPF;IAQI,iBAAA;EADF;AACF;AAGE;EACE,YAAA;EACA,cCdQ;EDeR,eAAA;EACA,gBAAA;AADJ;AAGI;EANF;IAOI,eAAA;EAAJ;AACF;AAEI;EACE,gCAAA;AAAN","sourcesContent":["@import \"src/styles/colors\";\n@import \"src/styles/sizes\";\n\n.choice {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  padding-top: 30px;\n  gap: 20px;\n\n  @media (max-width: $md) {\n    padding-top: 10px;\n  }\n\n  &_btn {\n    border: none;\n    color: $dark-blue;\n    font-size: 18px;\n    background: none;\n\n    @media (max-width: $md) {\n      font-size: 16px;\n    }\n\n    &__underline {\n      border-bottom: 1px solid $dark-blue;\n    }\n  }\n}","$green: #34a790;\n$aqua: #4ba1a7;\n$dark-blue: #253040;\n$asphalt-grey: #4f4f4f;\n$grey: #828282;\n$medium-grey: #bdbdbd;\n$semi-transparent-grey: #e0e0e0;\n$light-grey: #f2f2f2;\n$red: #eb5757;\n$white: #ffffff;\n$primary-green: #34A790;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"choice": "LookingRentTabs_choice__3693e",
	"choice_btn": "LookingRentTabs_choice_btn__aTqiW",
	"choice_btn__underline": "LookingRentTabs_choice_btn__underline__jAouV"
};
export default ___CSS_LOADER_EXPORT___;
