import React, {useEffect, useState} from 'react';
import FilterSmartSearchForm from '../../components/FilterOffersPage/FilterSmartSearchForm/FilterSmartSearchForm';
import FilterApplied from '../../components/FilterOffersPage/FilterApplied/FilterApplied';
import SubscriptionsForm from '../../components/SubscriptionsForm/SubscriptionsForm';
import {useDispatch, useSelector} from 'react-redux';
import filterSlice from '../../features/filter/filterSlice';
import {Type} from '../SearchPage/SearchPage';
import {FilterType, SubscriptionsFilterType} from '../../models/generalTypes';
import filterSelectors from '../../features/filter/filterSelectors';
import {useHistory} from 'react-router-dom';
import qs from 'qs';
import subscriptionOperation from '../../features/subscription/subscriptionOperation';
import LookingRentTabs from '../../components/LookingRentTabs/LookingRentTabs';

import subscriptionPageStyles from './SubscriptionPage.module.scss';

const SubscriptionPage = () => {

    const dispatch = useDispatch();

    const history = useHistory();

    const type = useSelector(filterSelectors.getType);

    const filter = useSelector(filterSelectors.getSubscriptionFilter) as SubscriptionsFilterType;

    const [filterFromQuery, setFilterFromQuery] = useState({} as FilterType);

    useEffect(() => {
        const parsed = qs.parse(history.location.search.substring(1), {
            delimiter: '&',
        });

        const actualFilter = {
            ...parsed,
        } as SubscriptionsFilterType;

        const filtersFromQuery: any = {
            city: actualFilter.city,
            price: actualFilter.price,
        };

        Object.keys(filtersFromQuery).forEach((key) => {
            if (filtersFromQuery[key] === undefined) {
                delete filtersFromQuery[key];
            }
        });

        if (Object.keys(filtersFromQuery).length !== 0) {
            dispatch(filterSlice.actions.setSubscriptionsFilter(filtersFromQuery));
            setFilterFromQuery(filtersFromQuery);
        }
    }, []);

    useEffect(() => {
        const sanitizeValue = (value: string | number, defaultValue = '0') =>
            value || value === 0 ? value : defaultValue;

        const query = {
            city: filter.city,
            price: sanitizeValue(filter.price),
        };

        const validFilters = Object.fromEntries(
            Object.entries(query).filter(([_, value]) =>
                value !== undefined &&
                value !== null &&
                value !== '' &&
                value !== '0',
            ),
        );

        const toFilter = qs.stringify(validFilters, {
            delimiter: '&',
            arrayFormat: 'indices',
        });

        history.push({
            pathname: 'subscriptions',
            search: toFilter,
        });
    }, [filter, history]);

    useEffect(() => {
        dispatch(subscriptionOperation.getSubscriptions({filter, size: 10, page: 0}));
    }, [filter]);

    useEffect(() => {
        if (type === Type.Looking && Object.keys(filterFromQuery).length !== 0) {
            dispatch(subscriptionOperation.getSubscriptions({filter, size: 10, page: 0}));
        }
    }, [type]);

    useEffect(() => {
        dispatch(filterSlice.actions.setType(Type.Looking));
    }, []);

    return (
        <div>
            <div className={subscriptionPageStyles.flex_container}>
                <LookingRentTabs />
                <FilterSmartSearchForm />
                <FilterApplied />
                <SubscriptionsForm />
            </div>
        </div>
    );
};

export default SubscriptionPage;