import React, {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import posed from 'react-pose';

function useOnClickOutside(ref: any, handler: (event: Event) => void) {
    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            if (!ref.current || ref.current.contains(event.target as Node) || (event.target as Element).closest('.modal-window')) {
                return;
            }
            handler(event);
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
}

const modalBackgroundPoses = {
    open: {
        top: '0 ',
        left: '0',
        position: 'absolute',
        background: 'rgba(0, 0, 0, 0.6)',
        zIndex: '9',
        applyAtStart: {
            display: 'block',
        },
    },
    closed: {
        background: 'rgba(0, 0, 0, 0)',
        applyAtEnd: {
            display: 'none',
        },
    },
};

const ModalBackground = styled(posed.div(modalBackgroundPoses))`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    height: 100%;
`;

const modalPoses = {
    open: {
        opacity: 1,
        transition: {
            opacity: {
                type: 'tween',
                duration: 200,
            },
        },
    },
    closed: {
        opacity: 0,
        transition: {
            opacity: {
                type: 'tween',
                duration: 200,
            },
        },
    },
};

const ModalWindow = styled(posed.div(modalPoses))`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px 0 rgba(50, 50, 93, 0.1);
`;

interface Props {
    children: React.ReactNode;
    height: string;
    width: string;
    isOpen: boolean;
    toggle: (arg0: boolean) => any | void;
}

const Modal: React.FC<Props> = ({
                                    width,
                                    height,
                                    isOpen,
                                    toggle,
                                    children,
                                }) => {
    const ref = useRef();

    useOnClickOutside(ref, () => toggle(false));

    const inputStyle = {
        width: width,
        height: height,
    };

    const poseValue = isOpen ? 'open' : 'closed';

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);

    return isOpen && document.getElementById('modal-root') ? (
        ReactDOM.createPortal(
            <ModalBackground initialPose="closed" pose={poseValue}>
                <ModalWindow style={inputStyle} ref={ref} className="modal-window">
                    {children}
                </ModalWindow>
            </ModalBackground>,
            document.getElementById('modal-root') as HTMLElement,
        )
    ) : null;

};

export default Modal;