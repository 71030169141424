import axios from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {baseURL} from '../base';
import {SubscriptionsFilterType, UserType} from '../../models/generalTypes';
import qs from 'qs';

axios.defaults.baseURL = 'http://api.rentbox.com.ua';

export type SubscribeType = {
    city: string;
    rooms: [];
    priceFrom: number | string | undefined;
    priceTo: number | string | undefined;
}

export type SubscriptionsType = {
    id: string | number;
    rooms: [];
    createdOn: string;
    priceFrom: number | string | undefined;
    priceTo: number | string | undefined;
    city: string;
    user: UserType;
}

export interface CredentialsSubscriptions {
    filter: SubscriptionsFilterType;
    size?: string | number;
    page: number;
    sort?: string;
}

const subscribe = createAsyncThunk(
    'subscription/subscribe',
    async (cred: SubscribeType) => {
        const {data} = await axios.post(
            `${baseURL}/subscriptions`,
            cred,
        );
        return data;
    },
);

export interface CredentialsSubscriptionAcceptances {
    offerId: number | string;
    subscriptionId: number | string;
}

const subscriptionAcceptances = createAsyncThunk(
    'subscription/subscription-acceptances',
    async ({offerId, subscriptionId}: CredentialsSubscriptionAcceptances) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            };
            const {data} = await axios.post(
                `${baseURL}/subscription-acceptances?offerId=${offerId}&subscriptionId=${subscriptionId}`,
                config,
            );
            return data;
        } catch (error) {
            console.log('error', error);
        }
    },
);

const getSubscriptions = createAsyncThunk(
    'subscription/subscriptions',
    async ({size = 10, filter, page, sort = 'createdOn,desc'}: CredentialsSubscriptions) => {
        try {
            const query = `city=${filter.city
            }&price=${
                filter.price === '' ||
                filter.price === undefined ||
                filter.price === null
                    ? ''
                    : filter.price
            }&`;

            const parsedQuery = qs.parse(query, {delimiter: '&'});

            type FilterTypeParsed = {
                city?: string;
                priceTo?: string | number | null;
            };

            const updValues: FilterTypeParsed = Object.fromEntries(
                Object.entries(parsedQuery).filter(
                    n =>
                        n[0] !== '' &&
                        n[1] !== 'undefined' &&
                        n[1] !== 'null' &&
                        n[1] !== undefined &&
                        n[1] !== 'false' &&
                        n[1] !== '' &&
                        n[1] !== null &&
                        n[1] !== '0' &&
                        n[1] !== '0-0' &&
                        n[1] !== '0-0 ',
                ),
            );

            const finalValues = {
                ...updValues,
            };

            const toFilter = qs.stringify(finalValues, {
                delimiter: '&',
                arrayFormat: 'indices',
            });

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            };
            const {data} = await axios.get(
                `${baseURL}/subscriptions?page=${page}&size=${size}&${toFilter}&sort=${sort}`,
                config,
            );
            return data;
        } catch (error) {
            console.log('error', error);
        }
    },
);

const operations = {
    subscribe,
    getSubscriptions,
    subscriptionAcceptances,
};

export default operations;