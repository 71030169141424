import axios from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {baseURL} from "../base";

axios.defaults.baseURL = 'http://api.rentbox.com.ua';

export interface CredentialsNotifications {
    read?: string;
    page?: number | string;
    size?: number | string;
    dateSort?: string;
    readSort?: string;
    isShowMore?: boolean;
}

const getNotifications = createAsyncThunk(
    'notification/getNotifications',
    async ({read = '', dateSort = '', size = 5, page = 0, readSort = '', isShowMore = false}: CredentialsNotifications) => {
        let str = '';
        if (readSort === '') {
            str = dateSort;
        } else str = readSort + '&sort=' + dateSort;

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            };
            const response = await axios.get(`${baseURL}/notifications?read=${read}&page=${page}&size=${size}&sort=${str}`, config);
            return { response, isShowMore };
        } catch (error) {
            console.log('error', error);
        }
    },
);

const updateNotification = createAsyncThunk(
    'notification/updateNotification',
    async (cred: { id: number }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            };
            const response = await axios.put(`${baseURL}/notifications/${cred.id}`, config);
            return response.data;
        } catch (error) {
            console.log('error', error);
        }
    }
)

const getUnreadCountNotifications = createAsyncThunk(
    'notification/getUnreadCountNotifications',
    async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            };
            const response = await axios.get(`${baseURL}/notifications/unread-count`, config);
            return response.data;
        } catch (error) {
            console.log('error', error);
        }
    },
);

const deleteNotification = createAsyncThunk(
    'notification/delete',
    async (id: number) => {
        try {
            const {data} = await axios.delete(`${baseURL}/notifications/${id}`);
            return data;
        } catch (error) {
            console.log('error', error);
        }
    }
);

const readNotification = createAsyncThunk(
    'notification/readNotification',
    async (id: number) => {
        try {
            const response = await axios.put(`${baseURL}/notifications/${id}`);
            return response.data;
        } catch (error) {
            console.log('error', error);
        }
    },
);

const operations = {
    getNotifications,
    getUnreadCountNotifications,
    updateNotification,
    deleteNotification,
    readNotification
};
export default operations;