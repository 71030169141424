import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import geolocationOperations from './geolocationOperations';

interface InitialState {
    cityObj: any;
    address: any;
    allCities: any;
    cityMarkers: any;
    isLoading: boolean;
    centerMapCity: {latitude: any; longitude: any};
    hoveredCoordinates: any;
}

const initialState: InitialState = {
    cityObj: {code: 'KBP', name: 'Kiev'},
    address: [],
    allCities: [],
    cityMarkers: [],
    isLoading: false,
    centerMapCity: {
        latitude: 50.4501,
        longitude: 30.5234,
    },
    hoveredCoordinates: null,
};

export type CityType = {
    code: string;
    name: string;
    latitude: string | number;
    longitude: string | number;
};

type Geo = {
    lat: string | number;
    lng: string | number;
};

const geolocationSlice = createSlice({
    name: 'geo',
    initialState,
    reducers: {
        setCoordinateWhenHover: {
            reducer: (state, action: PayloadAction<Geo>) => {
                state.hoveredCoordinates = action.payload;
            },
            prepare: (geo: Geo) => {
                return {payload: geo};
            },
        },
        setHoverNull: {
            reducer: (state, action: any) => {
                state.hoveredCoordinates = null;
            },
            prepare: () => {
                return {payload: {}};
            },
        },
        chooseCity: {
            reducer: (state, action: any) => {
                state.cityObj = action.payload;
                const chosenCity = state.allCities.find(
                    (city: CityType) => city.code === action.payload.code,
                );
                state.centerMapCity.latitude = chosenCity.latitude;
                state.centerMapCity.longitude = chosenCity.longitude;
            },
            prepare: (city) => {
                return {payload: city};
            },
        },
    },
    extraReducers: builder => {
        builder.addCase(
            geolocationOperations.getGeolocation.rejected,
            (state, action) => {
                state.isLoading = false;
            },
        );
        builder.addCase(
            geolocationOperations.getGeolocation.pending,
            (state, action) => {
                state.isLoading = true;
            },
        );
        builder.addCase(
            geolocationOperations.getGeolocation.fulfilled,
            (state, action: PayloadAction<{address: string; city: string}>) => {
                state.address = action.payload;
                state.isLoading = false;
            },
        );
        builder.addCase(
            geolocationOperations.getCities.fulfilled,
            (state, action) => {
                state.allCities = action.payload;
            },
        );
        builder.addCase(
            geolocationOperations.getCityMarkers.fulfilled,
            (state, action) => {
                state.cityMarkers = action.payload;
            },
        );
    },
});

export default geolocationSlice;