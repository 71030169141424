// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScrollToTopButton_scrollToTopHidden__USXxs {\n  display: none;\n}\n\n.ScrollToTopButton_scrollToTop__KVfak {\n  position: fixed;\n  bottom: 265px;\n  left: 90%;\n  width: 60px;\n  height: 60px;\n  background-color: #34A790;\n  border-radius: 50%;\n  border: none;\n  display: flex;\n  align-items: center;\n  z-index: 99999;\n  justify-content: center;\n}\n@media (max-width: 768px) {\n  .ScrollToTopButton_scrollToTop__KVfak {\n    left: 80%;\n  }\n}\n\n.ScrollToTopButton_main-content-contaiter__Jd1Q8 {\n  background-color: red;\n  width: 99px;\n}", "",{"version":3,"sources":["webpack://./src/components/ScrollToTopButton/ScrollToTopButton.module.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;AAFF;;AAKA;EACE,eAAA;EACA,aAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,yBCHc;EDId,kBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;EACA,uBAAA;AAFF;AAIE;EAdF;IAeI,SAAA;EADF;AACF;;AAIA;EACE,qBAAA;EACA,WAAA;AADF","sourcesContent":["@import '../../styles/_variables';\n@import '../../styles/sizes';\n\n.scrollToTopHidden {\n  display: none;\n}\n\n.scrollToTop {\n  position: fixed;\n  bottom: calc(20px + $footer-height);\n  left: 90%;\n  width: 60px;\n  height: 60px;\n  background-color: $scroll-to-top-button-background-color;\n  border-radius: 50%;\n  border: none;\n  display: flex;\n  align-items: center;\n  z-index: 99999;\n  justify-content: center;\n\n  @media (max-width: $md) {\n    left: 80%;\n  }\n}\n\n.main-content-contaiter {\n  background-color: red;\n  width: 99px;\n}","$green: #34a790;\n$aqua: #4ba1a7;\n$dark-blue: #253040;\n$asphalt-grey: #4f4f4f;\n$grey: #828282;\n$medium-grey: #bdbdbd;\n$semi-transparent-grey: #e0e0e0;\n$light-grey: #f2f2f2;\n$red: #eb5757;\n$white: #ffffff;\n$primary-green: #34A790;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollToTopHidden": "ScrollToTopButton_scrollToTopHidden__USXxs",
	"scrollToTop": "ScrollToTopButton_scrollToTop__KVfak",
	"main-content-contaiter": "ScrollToTopButton_main-content-contaiter__Jd1Q8"
};
export default ___CSS_LOADER_EXPORT___;
