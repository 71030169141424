import React, {useState} from 'react';
import Modal from '../../../../common/Modal';
import DatePicker from 'react-datepicker';
import {AcceptanceType, CreateAcceptanceSuccessActionPayloadType} from '../../../../models/generalTypes';
import {useForm} from 'react-hook-form';
import acceptanceOperations from '../../../../features/acceptance/acceptanceOperations';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import LoaderButtonFormService from '../../../../common/Button/loaderButtonFormService/LoaderButtonFormService';
import useWindowSize from '../../../../hooks/useWindowSize';

import LoaderColor from '../../../../common/Button/enum/LoaderColor';
import Status from '../../enums/Status';

import closeButton from '../../images/closeButton.svg';
import paw from '../../images/pet.svg';
import security from '../../images/security.svg';

import 'react-datepicker/dist/react-datepicker.css';
import u from './UpdateAcceptanceModal.module.scss';
import modalStyles from '../../../../styles/modalStyles.module.scss';

interface Props {
    isModalOpen: boolean;
    toggleModal: (arg0: boolean) => any | void;
    acceptanceId: string;
    acceptance: AcceptanceType;
}

const UpdateAcceptanceModal: React.FC<Props> = ({isModalOpen, toggleModal, acceptance, acceptanceId}) => {

    const {
        register,
        setValue,
        handleSubmit,
        clearErrors,
        formState: {errors},
    } = useForm();

    const dispatch = useDispatch();

    const {t} = useTranslation();

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const [startDate, setStartDate] = useState(tomorrow);

    const submit = async (values: any) => {

        const todayDate = new Date();

        const afterYear = todayDate.getUTCFullYear() + 1;

        todayDate.setFullYear(afterYear);

        const isoDayAfterYear = todayDate.toISOString();

        const updValues = {
            ...values,
            moveInDate: startDate,
            expiresOn: isoDayAfterYear,
            status: Status.WaitingSolo,
        };

        const cred = {
            id: acceptanceId as string,
            credentials: updValues as CreateAcceptanceSuccessActionPayloadType,
        };

        acceptanceId !== undefined && await dispatch(acceptanceOperations.updateAcceptance(cred));

        toggleModal(false);
    };

    const {isScreenResolutionMobile} = useWindowSize();

    const [characterCount, setCharacterCount] = useState(0);

    const handleCharacterChange = (e: any) => {
        const inputText = e.target.value;
        setCharacterCount(inputText.length);
    };

    return (
        <Modal
            width={isScreenResolutionMobile ? '100%' : '50%'}
            height={isScreenResolutionMobile ? '100%' : '650px'}
            isOpen={isModalOpen}
            toggle={toggleModal}
        >
            <h4 className={modalStyles.header}>{t('acceptancePage.updateAcceptanceModal.title')}</h4>
            <div
                role="button"
                tabIndex={1}
                onKeyDown={() => toggleModal(!isModalOpen)}
                onClick={() => {
                    toggleModal(!isModalOpen);
                }}
                className={modalStyles.close}
            >
                <p>
                    <img src={closeButton} alt="closeButton" />
                </p>
            </div>

            <div className={modalStyles.line}></div>

            {acceptance !== undefined &&
              <form onSubmit={handleSubmit(submit)} className={u.form}>

                <div className={u.scroll}>

                  <div className={u.column}>
                    <h4 className={u.subtitle}>{t('acceptancePage.updateAcceptanceModal.price')}</h4>
                    <div>
                      <div className={u.row}>
                        <input
                          defaultValue={acceptance.price}
                          type="number"
                          className={
                              errors.price
                                  ? `${u.error}`
                                  : `${u.input_block}`
                          }
                          {...register('price', {
                              required: 'incorrect field',
                              pattern: {
                                  value: /^[1-9][0-9]*$/,
                                  message: 'entered value does not match price format',
                              },
                              min: {
                                  value: 0,
                                  message: 'min > 0',
                              },
                          })}
                        />
                        <input
                          className={`${u.input_cur} ${u.form_control} ${u.currency}`}
                          value={acceptance.currency}
                          {...register('currency')}
                          readOnly={true}
                        />
                      </div>
                      <div>
                          {errors.price ? (
                              <p className={u.error_message_price}>{errors.price.message}</p>
                          ) : (
                              <p></p>
                          )}
                      </div>
                    </div>

                    <div className={u.indent}>
                      <div
                        className={u.paragraph}>{t('acceptancePage.updateAcceptanceModal.petDeposit')}</div>
                      <div className={u.price_flex}>
                        <input
                          defaultValue={acceptance.petDeposit}
                          type="number"
                          disabled={true}
                          className={
                              errors.petDeposit
                                  ? `${u.error}`
                                  : `${u.input_block} ${u.disabled_bgc}`
                          }
                          {...register('petDeposit', {
                              required: 'incorrect field',
                              pattern: {
                                  value: /^[1-9][0-9]*$/,
                                  message: 'entered value does not match price format',
                              },
                              min: {
                                  value: 0,
                                  message: 'min > 0',
                              },
                          })}
                        />
                        <span className={`${u.input_cur} ${u.form_control}`}>
                                    <img className={u.img_input} src={paw} alt="paw" />
                                </span>
                      </div>
                      <div>
                          {errors.petDeposit ? (
                              <p className={u.error_message}>{errors.petDeposit.message}</p>
                          ) : (
                              <p></p>
                          )}
                      </div>
                    </div>

                    <div className={u.indent}>
                      <div
                        className={u.paragraph}>{t('acceptancePage.updateAcceptanceModal.securityDeposit')}</div>
                      <div className={u.price_flex}>
                        <input
                          type="number"
                          defaultValue={acceptance.securityDeposit}
                          disabled={true}
                          className={
                              errors.securityDeposit
                                  ? `${u.error}`
                                  : `${u.input_block} ${u.disabled_bgc}`
                          }
                          {...register('securityDeposit', {
                              required: 'incorrect field',
                              pattern: {
                                  value: /^[1-9][0-9]*$/,
                                  message: 'entered value does not match price format',
                              },
                              min: {
                                  value: 0,
                                  message: 'min > 0',
                              },
                          })}
                        />
                        <span className={`${u.input_cur} ${u.form_control}`}>
                                    <img className={u.img_input} src={security} alt="security" />
                                </span>
                      </div>
                      <div>
                          {errors.securityDeposit ? (
                              <p className={u.error_message}>
                                  {errors.securityDeposit.message}
                              </p>
                          ) : (
                              <p></p>
                          )}
                      </div>
                    </div>

                    <hr className={u.sub_line}></hr>

                    <h4 className={u.subtitle}>{t('acceptancePage.updateAcceptanceModal.date.title')}</h4>

                    <div className={isScreenResolutionMobile ? u.f_column : u.row}>

                      <div className={u.lap}>{t('acceptancePage.updateAcceptanceModal.date.checkIn')}</div>

                      <div style={{width: '100px'}}>
                        <DatePicker
                          disabled={true}
                          autoComplete="off"
                          name="moveInDate"
                          className={`${u.input_block} ${u.date}`}
                          selected={startDate}
                          onChange={(val: any) => {
                              setStartDate(val);
                              setValue('moveInDate', val);
                          }}
                          dateFormat="dd.MM.yyyy"
                        />
                      </div>
                    </div>

                    <hr className={u.sub_line}></hr>

                    <div>
                      <h4 className={u.subtitle}>{t('acceptancePage.updateAcceptanceModal.comment')}</h4>
                      <p className={u.character}>{characterCount}/300</p>
                      <textarea
                        maxLength={300}
                        className={
                            errors.comment
                                ? `${u.comment}`
                                : `${u.comment}`
                        }
                        {...register('comment', {
                            required: 'incorrect field',
                            onChange: handleCharacterChange,
                        })}
                      />
                        {errors.title ? (
                            <p className={u.error_message}>{errors.title.message}</p>
                        ) : (
                            <p></p>
                        )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className={u.down_line}></div>
                  <div className={isScreenResolutionMobile ? u.f_column__bottom : u.space}>
                    <input
                      className={u.clear_btn}
                      value={`${t('acceptancePage.updateAcceptanceModal.btn.clear')}`}
                      type="reset"
                      onClick={() => clearErrors()}
                    />
                    <LoaderButtonFormService
                      buttonContent={t('acceptancePage.updateAcceptanceModal.btn.submit')}
                      buttonClassName={'update_acceptance_btn'} submit={handleSubmit(submit)}
                      color={LoaderColor.White} />
                  </div>
                </div>
              </form>}
        </Modal>
    );
};

export default UpdateAcceptanceModal;