import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import QuestionBlock from './questionBlock/QuestionBlock';
import {useDispatch, useSelector} from 'react-redux';
import filterSelectors from '../../features/filter/filterSelectors';
import {FilterType} from '../../models/generalTypes';
import {useHistory} from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize';
import SelectCityDropDown from '../../components/FilterHomePage/SelectCityDropDown/SelectCityDropDown';
import DropdownLink from '../../components/FilterHomePage/DropDownRooms/DropdownLink';
import DropDownCurrencyPrice from '../../components/FilterHomePage/DropDownCurrencyPrice/DropDownCurrencyPrice';
import {useForm} from 'react-hook-form';
import {emailPattern, phonePattern} from '../../pattern/formPattern';
import contactOperations from '../../features/contact/contactOperations';
import authSelectors from '../../features/auth/authSelectors';
import LoaderButtonFormService from '../../common/Button/loaderButtonFormService/LoaderButtonFormService';
import geolocationOperations from '../../features/geolocation/geolocationOperations';
import AuthModal from '../../components/Authentification/AuthModal/AuthModal';
import ScrollGif from './scrollGif/ScrollGif';

import describePhoto from '../../assets/icons/homePage/describe-photo.svg';
import first from '../../assets/icons/homePage/first.svg';
import second from '../../assets/icons/homePage/second.svg';
import third from '../../assets/icons/homePage/third.svg';
import fourth from '../../assets/icons/homePage/fourth.svg';
import rocket from '../../assets/icons/homePage/rocket.svg';
import eMail from '../../assets/icons/homePage/mail.svg';
import photo from '../../assets/icons/homePage/photo.svg';
import add from '../../assets/icons/createOfferBtn/add.svg';

import LoaderColor from '../../common/Button/enum/LoaderColor';
import WindowSize from '../../enums/WindowSize';

import homePageStyles from './HomePage.module.scss';

const HomePage = () => {

    const [isRent, setIsRent] = useState(false);

    const history = useHistory();

    const [isModalOpen, toggleModal] = useState(false);

    const {t} = useTranslation();

    const isAuthenticated = useSelector(authSelectors.getIsAuthenticated);

    const filter = useSelector(filterSelectors.getFilter) as FilterType;

    const [city, setCity] = useState({code: 'KBP', name: filter.city});

    const {isScreenResolutionMobile, windowSize} = useWindowSize();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(geolocationOperations.getCities());
    }, []);

    const {
        register,
        formState: {errors},
        handleSubmit,
        clearErrors,
        reset,
        watch,
    } = useForm();

    const getSelectWidth = () => {
        return windowSize.width < WindowSize.MD ? 310 : 238;
    };

    const submit = async () => {
        const name = watch('name');
        const phone = watch('phone');
        const email = watch('email');
        const message = watch('message');
        await dispatch(contactOperations.contactUs({name, phone, email, message}));
        reset({message: ''});
    };

    const [phone, setPhone] = useState('+380');

    const formatPhoneNumber = (input: string): string => {
        const cleaned = ('' + input).replace(/\D/g, '');

        const match = cleaned.match(/^(\d{1,3})(\d{0,2})(\d{0,3})(\d{0,4})$/);

        if (match) {
            return `+${match[1]}${match[2] ? ' ' + match[2] : ''}${match[3] ? ' ' + match[3] : ''}${match[4] ? ' ' + match[4] : ''}`;
        }

        return cleaned;
    };

    const changeHandler = (e: any) => {
        const {value} = e.target;
        setPhone(formatPhoneNumber(value));
        if (phonePattern.test(e.target.value)) {
            clearErrors('phone');
        }
    };

    return (
        <div className={homePageStyles.main}>

            <div className={homePageStyles.header_background}>
                <div className={homePageStyles.choice}>
                    <button onClick={() => setIsRent(false)}
                            className={`${homePageStyles.choice_btn} ${!isRent && homePageStyles.choice_btn__underline}`}>
                        {t('homePage.choice.looking')}
                    </button>
                    <button onClick={() => setIsRent(true)}
                            className={`${homePageStyles.choice_btn} ${isRent && homePageStyles.choice_btn__underline}`}>
                        {t('homePage.choice.rent')}
                    </button>
                </div>
                {isRent ?
                    <>
                        <h1 className={homePageStyles.header_background__text}>{t('homePage.header.renting.title')}
                            {isScreenResolutionMobile && <br />} <span
                                className={homePageStyles.header_background__text_without}>{t('homePage.header.renting.without')}</span>
                            <p>{t('homePage.header.renting.earn')}</p>
                        </h1>
                    </>
                    :
                    <>
                        <h1 className={homePageStyles.header_background__text}>{t('homePage.header.looking.title')}
                            {isScreenResolutionMobile && <br />} <span
                                className={homePageStyles.header_background__text_without}>{t('homePage.header.looking.without')}</span>
                        </h1>
                        <div className={homePageStyles.search_flex}>
                            <SelectCityDropDown city={city} setCity={setCity} />
                            <div className={homePageStyles.search_separator} />
                            <DropdownLink width={getSelectWidth()} borderRadius="100px" />
                            <div className={homePageStyles.search_separator} />
                            <DropDownCurrencyPrice width={getSelectWidth()} borderRadius="100px" />
                            {isScreenResolutionMobile && <div className={homePageStyles.search_separator} />}
                            <button
                                className={homePageStyles.search_btn}
                                onClick={() => history.push(`/offers?currency=UAH&city=${city.name}`)}
                            >
                                <div className={homePageStyles.search} />
                            </button>
                        </div>
                    </>}
                {!isScreenResolutionMobile &&
                  <>{isRent ?
                      (isAuthenticated ?
                          <button
                              onClick={() => history.push('/create')}
                              className={homePageStyles.start_btn}>
                              {t('homePage.btn.add')}
                              <img src={add} alt="add" className="add_svg" />
                          </button> :
                          <button
                              onClick={() => toggleModal(!isModalOpen)}
                              className={homePageStyles.start_btn}>
                              {t('homePage.btn.start')}
                          </button>)
                      :
                      <button
                          className={homePageStyles.start_btn}
                          onClick={() => history.push(`/offers?currency=UAH&city=${city.name}`)}>
                          {t('homePage.btn.show')}
                      </button>}
                  </>}
            </div>

            {isScreenResolutionMobile &&
              <>{isRent ?
                  (isAuthenticated ?
                      <button
                          onClick={() => history.push('/create')}
                          className={homePageStyles.start_btn}>
                          {t('homePage.btn.add')}
                          <img src={add} alt="add" className="add_svg" />
                      </button> :
                      <button
                          onClick={() => toggleModal(!isModalOpen)}
                          className={homePageStyles.start_btn}>
                          {t('homePage.btn.start')}
                      </button>)
                  :
                  <button
                      className={homePageStyles.start_btn}
                      onClick={() => history.push(`/offers?currency=UAH&city=${city.name}`)}>
                      {t('homePage.btn.show')}
                  </button>}
              </>}

            <section id="about" className={homePageStyles.describe}>
                <div className={homePageStyles.describe_flex}>
                    <img src={describePhoto} alt="describePhoto" className={homePageStyles.describe_photo} />
                    <span className={homePageStyles.describe_text}>
                        <h2 className={homePageStyles.describe_text_h}>{t('homePage.describe.title')}<span
                            className={homePageStyles.describe_text_h_green}>{t('homePage.describe.subTitle')}</span></h2>
                        <p className={homePageStyles.describe_text_p}>
                            {t('homePage.describe.textTitle')}
                        </p>
                        <p className={homePageStyles.describe_text_p}>
                            {t('homePage.describe.text')}
                        </p>
                    </span>
                </div>
            </section>

            <section id="advantages" className={homePageStyles.advantages}>
                <div className={homePageStyles.advantages_text}>
                    <h2 className={homePageStyles.advantages_text_h}>
                        {t('homePage.advantages.title')}
                    </h2>
                    <p className={homePageStyles.advantages_text_p}>
                        {t('homePage.advantages.text')}
                    </p>
                </div>
                <div className={homePageStyles.advantages_text_stairs}>
                    <div className={homePageStyles.advantages_text_stairs_span}>
                        <img src={first} alt="first" />
                        <h4 className={homePageStyles.advantages_text_stairs_h}>{t('homePage.advantages.textStairs.first.title')}</h4>
                        <div className={homePageStyles.advantages_text_stairs_line}></div>
                        <p className={homePageStyles.advantages_text_stairs_p}>{t('homePage.advantages.textStairs.first.text')}</p>
                    </div>
                    <div className={homePageStyles.advantages_text_stairs_span}>
                        <img src={second} alt="second" className={homePageStyles.advantages_text_stairs_img_second} />
                        <h4 className={homePageStyles.advantages_text_stairs_h}>{t('homePage.advantages.textStairs.second.title')}</h4>
                        <div className={homePageStyles.advantages_text_stairs_line}></div>
                        <p className={homePageStyles.advantages_text_stairs_p}>{t('homePage.advantages.textStairs.second.text')}</p>
                    </div>
                    <div className={homePageStyles.advantages_text_stairs_span}>
                        <img src={third} alt="third" className={homePageStyles.advantages_text_stairs_img_third} />
                        <h4 className={homePageStyles.advantages_text_stairs_h}>{t('homePage.advantages.textStairs.third.title')}</h4>
                        <div className={homePageStyles.advantages_text_stairs_line}></div>
                        <p className={homePageStyles.advantages_text_stairs_p}>{t('homePage.advantages.textStairs.third.text')}</p>
                    </div>
                    <div className={homePageStyles.advantages_text_stairs_span}>
                        <img src={fourth} alt="fourth" className={homePageStyles.advantages_text_stairs_img_fourth} />
                        <h4 className={homePageStyles.advantages_text_stairs_h}>{t('homePage.advantages.textStairs.fourth.title')}</h4>
                        <div className={homePageStyles.advantages_text_stairs_line}></div>
                        <p className={homePageStyles.advantages_text_stairs_p}>{t('homePage.advantages.textStairs.fourth.text')}</p>
                    </div>
                </div>
            </section>

            <div className={homePageStyles.reminder}>
                <img src={rocket} alt="rocket" />
                <div className={homePageStyles.reminder_text}>
                    <h3 className={homePageStyles.reminder_text_h}>{t('homePage.reminder.title')}</h3>
                    {isRent ?
                        <p className={homePageStyles.reminder_text_p}>
                            {t('homePage.reminder.text.renting')}
                        </p>
                        :
                        <p className={homePageStyles.reminder_text_p}>
                            {t('homePage.reminder.text.looking')}
                        </p>}
                    <p className={homePageStyles.reminder_text_p}>{t('homePage.reminder.text.come')}</p>
                </div>
                {isAuthenticated ?
                    <button
                        className={homePageStyles.reminder_btn}
                        onClick={() => history.push('/create')}
                    >
                        {t('homePage.btn.add')}
                        <img src={add} alt="add" className="add_svg" />
                    </button>
                    :
                    <button
                        className={homePageStyles.reminder_btn}
                        onClick={() => toggleModal(!isModalOpen)}
                    >
                        {t('homePage.btn.start')}
                    </button>}
            </div>

            <section id="steps" className={homePageStyles.steps}>
                <ScrollGif isRent={isRent} />
            </section>

            <div className={homePageStyles.questions}>
                <h2 className={homePageStyles.questions_h}>{t('homePage.questions.title')}
                    <br />{t('homePage.questions.subTitle')}</h2>
                <div className={homePageStyles.questions_flex}>
                    {!isScreenResolutionMobile &&
                      <div className={homePageStyles.questions_photo_block}>
                        <img src={photo} alt="photo" />
                        <p className={homePageStyles.questions_photo_block_p}>{t('homePage.questions.pc.text')}
                        </p>
                        <a href="#e_mail"
                           className={homePageStyles.questions_photo_block_btn}>{t('homePage.questions.pc.btn')}</a>
                      </div>}
                    <div className={homePageStyles.questions_question_block}>
                        <hr className={homePageStyles.questions_question_block_line} />
                        <QuestionBlock
                            number={t('homePage.questions.questionBlock.one.number')}
                            question={t('homePage.questions.questionBlock.one.question')}
                            answer={t('homePage.questions.questionBlock.one.answer')}
                        />
                        <QuestionBlock
                            number={t('homePage.questions.questionBlock.two.number')}
                            question={t('homePage.questions.questionBlock.two.question')}
                            answer={t('homePage.questions.questionBlock.two.answer')}
                        />
                        <QuestionBlock
                            number={t('homePage.questions.questionBlock.three.number')}
                            question={t('homePage.questions.questionBlock.three.question')}
                            answer={t('homePage.questions.questionBlock.three.answer')}
                        />
                        <QuestionBlock
                            number={t('homePage.questions.questionBlock.four.number')}
                            question={t('homePage.questions.questionBlock.four.question')}
                            answer={t('homePage.questions.questionBlock.four.answer')}
                        />
                    </div>
                </div>
            </div>

            <section id="e_mail" className={homePageStyles.e_mail}>
                <h2 className={homePageStyles.e_mail_h}>
                    {t('homePage.email.title')}
                    <br />{t('homePage.email.subTitle')}
                </h2>
                <p className={homePageStyles.e_mail_p}>
                    {t('homePage.email.text')} <br />{t('homePage.email.subText')}
                </p>
                <div className={homePageStyles.e_mail_flex}>
                    <form onSubmit={handleSubmit(submit)} className={homePageStyles.form}>
                        <input
                            type="text"
                            {...register('name', {
                                required: t('formErrors.required').toString(),
                                minLength: {
                                    value: 2,
                                    message: t('formErrors.minLength'),
                                },
                            })}
                            className={errors.name ? homePageStyles.e_mail_input_error : homePageStyles.e_mail_input}
                            placeholder={t('homePage.email.placeholder.name')}
                        />
                        {!isScreenResolutionMobile ?
                            <div className="flex-row gap-2">
                                <input
                                    className={errors.phone ? homePageStyles.e_mail_input_error : homePageStyles.e_mail_input}
                                    {...register('phone', {
                                        pattern: {
                                            value: phonePattern,
                                            message: t('formErrors.pattern'),
                                        },
                                        required: t('formErrors.required').toString(),
                                    })}
                                    value={phone}
                                    maxLength={16}
                                    onChange={changeHandler}
                                    placeholder={t('homePage.email.placeholder.phone')}
                                />
                                <input
                                    type="text"
                                    className={errors.email ? homePageStyles.e_mail_input_error : homePageStyles.e_mail_input}
                                    placeholder={t('homePage.email.placeholder.email')}
                                    {...register('email', {
                                        required: t('formErrors.required').toString(),
                                        pattern: {
                                            value: emailPattern,
                                            message: t('formErrors.invalidEmail'),
                                        },
                                        minLength: {
                                            value: 2,
                                            message: t('formErrors.minLength'),
                                        },
                                    })}
                                />
                            </div> :
                            <>
                                <input
                                    type="text"
                                    className={errors.phone ? homePageStyles.e_mail_input_error : homePageStyles.e_mail_input}
                                    {...register('phone', {
                                        pattern: {
                                            value: phonePattern,
                                            message: t('formErrors.pattern'),
                                        },
                                        required: t('formErrors.required').toString(),
                                    })}
                                    value={phone}
                                    maxLength={16}
                                    onChange={changeHandler}
                                    placeholder={t('homePage.email.placeholder.phone')}
                                />
                                <input
                                    type="text"
                                    className={errors.email ? homePageStyles.e_mail_input_error : homePageStyles.e_mail_input}
                                    {...register('email', {
                                        required: t('formErrors.required').toString(),
                                        pattern: {
                                            value: emailPattern,
                                            message: t('formErrors.invalidEmail'),
                                        },
                                        minLength: {
                                            value: 2,
                                            message: t('formErrors.minLength'),
                                        },
                                    })}
                                    placeholder={t('homePage.email.placeholder.email')}
                                />
                            </>}
                        <textarea
                            className={errors.message ? homePageStyles.e_mail_message_error : homePageStyles.e_mail_message}
                            placeholder={t('homePage.email.placeholder.message')}
                            {...register('message', {
                                required: t('formErrors.required').toString(),
                            })}
                        />
                        <br />
                        <LoaderButtonFormService buttonContent={t('homePage.email.btn')}
                                                 buttonClassName={homePageStyles.e_mail_btn}
                                                 submit={handleSubmit(submit)}
                                                 color={LoaderColor.White}
                        />
                    </form>
                    {!isScreenResolutionMobile && <img src={eMail} alt="mail" className={homePageStyles.email_img} />}
                </div>
            </section>

            <div className={homePageStyles.contact}>
                <div className={homePageStyles.contact_flex}>
                    <svg width="468" height="66" viewBox="0 0 468 66" fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                         className={homePageStyles.nav_link_size}>
                        <path
                            d="M326.278 65.6574V0.25H391.238V65.6574H326.278ZM343.616 47.8717H373.465V17.9764H343.616V47.8717Z"
                            fill="#34a790" />
                        <path
                            d="M306.497 30.8237C314.116 32.8913 318.89 37.0925 319.693 44.7046C320.477 52.1916 318.014 58.6315 311.291 62.2598C307.781 64.1562 303.402 64.6633 299.333 65.2361C295.343 65.7959 291.247 65.7629 287.197 65.7893C277.88 65.8551 268.562 65.7959 259.238 65.7893C258.171 65.7893 257.104 65.7893 255.82 65.7893V0.223883C256.617 0.151449 257.453 0.0131625 258.283 0.0131625C271.222 -7.23404e-06 284.155 0.0131697 297.094 0C300.861 0 304.555 0.375329 307.939 2.15324C313.55 5.09667 316.625 9.6929 316.691 16.1197C316.75 22.4214 314.03 27.044 308.315 29.8294C307.834 30.0664 307.373 30.343 306.497 30.8237ZM276.326 51.5331C281.119 51.5331 285.808 51.566 290.496 51.5133C291.8 51.5001 293.13 51.3487 294.388 51.0194C297.759 50.1305 299.478 47.8653 299.405 44.6321C299.333 41.5504 297.687 39.1074 294.223 38.8572C288.277 38.4358 282.278 38.7387 276.326 38.7387V51.5331ZM276.319 25.4241C281.686 25.4241 286.71 25.7138 291.681 25.3253C295.303 25.0422 296.837 22.7638 296.778 19.3068C296.719 16.1065 295.086 13.8545 291.635 13.6175C286.565 13.2685 281.455 13.5253 276.319 13.5253V25.4175V25.4241Z"
                            fill="#34a790" />
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M420.435 0.303223H397.118C397.118 0.303239 397.118 0.303256 397.118 0.303272H397.052C399.901 4.244 402.716 8.13867 405.516 12.0124C410.57 19.0577 415.565 26.0204 420.572 32.9938C415.565 39.9671 410.57 46.9298 405.516 53.9751C402.716 57.8488 399.901 61.7435 397.052 65.6842H397.118C397.118 65.6842 397.118 65.6843 397.118 65.6843H420.435C420.435 65.6843 420.435 65.6842 420.435 65.6842H420.692C424.557 60.3307 428.409 54.997 432.4 49.4789C434.084 51.8056 435.711 54.0537 437.291 56.2373C439.646 59.4921 441.898 62.6036 444.081 65.6184H467.721C459.998 54.9522 452.348 44.384 444.75 33.8887C444.534 33.5904 444.318 33.292 444.102 32.9938C444.317 32.6961 444.533 32.3984 444.749 32.1006C452.347 21.6047 459.998 11.0359 467.721 0.369104H444.081C441.897 3.38489 439.645 6.49745 437.289 9.75339C435.709 11.936 434.083 14.1831 432.4 16.5086C428.409 10.9905 424.557 5.65676 420.692 0.303272H420.435C420.435 0.303256 420.435 0.303239 420.435 0.303223Z"
                              fill="#34a790" />
                        <path
                            d="M0 65.117V0H35.1874C40.6475 0 45.1302 1.04484 48.6355 3.13451C52.2082 5.15678 54.8371 7.95425 56.5223 11.5269C58.275 15.0996 59.1513 19.1778 59.1513 23.7616C59.1513 28.1432 58.039 32.0192 55.8145 35.3897C53.59 38.6927 50.7926 41.3216 47.4221 43.2765C48.7029 44.2876 49.7814 45.7706 50.6578 47.7255L58.2413 65.117H35.5919L28.9184 50.0511C28.4465 49.0399 27.941 48.3322 27.4017 47.9277C26.8624 47.5232 26.0198 47.321 24.8739 47.321H21.7394V65.117H0ZM21.7394 30.5362H29.7273C34.5807 30.5362 37.0075 28.278 37.0075 23.7616C37.0075 19.1778 34.7493 16.8859 30.2329 16.8859H21.7394V30.5362Z"
                            fill="#253040" />
                        <path
                            d="M65.6426 65.9998V0.381348H116.69V16.5822H87.5494V24.9373H112.92V41.5457H87.5494V49.7989H116.69V65.9998H65.6426Z"
                            fill="#253040" />
                        <path
                            d="M124.298 65.9998V0.381348H142.741L164.342 30.6433V0.381348H186.248V65.9998H167.602L146.205 35.8398V65.9998H124.298Z"
                            fill="#253040" />
                        <path
                            d="M209.936 65.9998V16.3784H192.411V0.381348H249.47V16.3784H231.843V65.9998H209.936Z"
                            fill="#253040" />
                    </svg>
                    {!isScreenResolutionMobile ?
                        <div className="flex-row gap-5">
                            <p className={homePageStyles.contact_p_privacy}
                               onClick={() => history.push('/privacy')}>{t('homePage.footer.privacy')}</p>
                            <p className={homePageStyles.contact_p_privacy}
                               onClick={() => history.push('/wrong')}
                            >
                                {t('homePage.contact')}
                            </p>
                            <p className={homePageStyles.contact_p}>{t('homePage.footer.text')} <span
                                className={homePageStyles.contact_span}>{t('homePage.footer.contact')}</span></p>
                        </div>
                        :
                        <>
                            <p className={`${homePageStyles.contact_p} mb-3`}
                               onClick={() => history.push('/privacy')}>{t('homePage.footer.privacy')}</p>
                            <p className={homePageStyles.contact_p}
                               onClick={() => history.push('/wrong')}
                            >
                                {t('homePage.contact')}
                            </p>
                            <p className={homePageStyles.contact_p}>{t('homePage.footer.text')} <a
                                href="mailto:rentbox.acc@gmail.com"
                                className={homePageStyles.contact_span}>{t('homePage.footer.contact')}</a></p>
                        </>}
                </div>
            </div>
            <AuthModal isAuthModalOpen={isModalOpen} toggleAuthModal={toggleModal} />
        </div>
    );
};

export default HomePage;