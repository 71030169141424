import {AppStateType} from '../../redux/store';
import {filteredOffersAdapter} from "./filterSlice";

const getFilteredOffers = filteredOffersAdapter.getSelectors<AppStateType>(
    (state) => state.filter.filteredOffers,
).selectAll;

const getTotalPages = (state: AppStateType) =>
    state.filter.totalPages;

const isLoading = (state: AppStateType) =>
    state.filter.isLoading;

const getType = (state: AppStateType) =>
    state.filter.type;

const isChangeType = (state: AppStateType) =>
    state.filter.isChangeType;

export const getFilter = (state: AppStateType) =>
    state.filter.filter;

export const getSubscriptionFilter = (state: AppStateType) =>
    state.filter.subscriptionsFilter;

export const getTotalElements = (state: AppStateType) =>
    state.filter.totalElements;

const getSmartSearchedOffers = (state: AppStateType) =>
    state.filter.smartSearchedOffers;

const getFilterAppliedWidth = (state: AppStateType) =>
    state.filter.filterAppliedWidth;

const filterSelectors = {
    getFilteredOffers,
    isChangeType,
    getFilter,
    getSmartSearchedOffers,
    getType,
    getTotalElements,
    isLoading,
    getTotalPages,
    getFilterAppliedWidth,
    getSubscriptionFilter
};

export default filterSelectors;