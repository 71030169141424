import {AppStateType} from '../../redux/store';
import {notificationsAdapter} from './notificationSlice';

const getNotifications = notificationsAdapter.getSelectors<AppStateType>(
    state => state.notification.notifications,
);

const getUnreadCountNotifications = (state: AppStateType) =>
    state.notification.unreadCount;

const getNotificationCount = (state: AppStateType) =>
    state.notification.notificationCount;

const getTotalPages = (state: AppStateType) =>
    state.notification.totalPages;

const isLoading = (state: AppStateType) =>
    state.notification.isLoading;

const getNotificationAcceptanceId = (state: AppStateType) =>
    state.notification.notificationAcceptanceId;

const notificationSelectors = {
    getNotifications,
    getUnreadCountNotifications,
    getNotificationCount,
    isLoading,
    getTotalPages,
    getNotificationAcceptanceId,
};

export default notificationSelectors;
