import React, {useEffect, useState} from 'react';
import {useHistory, Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import UserProfile from '../../components/UserProfile/UserProfile';
import {OfferElementsType, ResponseRefreshUserSuccessActionPayloadType} from '../../models/generalTypes';
import MyOffersAndAcceptancesForm from '../../components/MyOffersAndAcceptancesForm/MyOffersAndAcceptancesForm';
import userSelectors from '../../features/user/userSelectors';
import acceptanceOperations from '../../features/acceptance/acceptanceOperations';
import Acceptances from '../../components/Acceptances/MyAcceptancesPage/Acceptances';
import {useTranslation} from 'react-i18next';
import NotificationPage from '../../components/Notification/NotificationPage';

import accountStyles from './Account.module.scss';
import 'react-tabs/style/react-tabs.css';

enum ActiveTab {
    Profile = '/account/profile',
    Offer = '/account/offers',
    Acceptance = '/account/acceptances',
    Notification = '/account/notifications'
}

const Account: React.FC = () => {

    const {t} = useTranslation();

    const userOffers = useSelector(
        userSelectors.getUserOffers.selectAll,
    ) as Array<OfferElementsType>;

    const user = useSelector(
        userSelectors.getUserProfile,
    ) as ResponseRefreshUserSuccessActionPayloadType;

    const arrayOfIds = userOffers.map(offer => offer.id).join();

    const history = useHistory();

    useEffect(() => {
        if (userOffers.length !== 0) {
            dispatch(acceptanceOperations.getOffersAcceptancesCount(arrayOfIds));
        }
    }, [userOffers]);

    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState(history.location.pathname);

    useEffect(() => {
        setActiveTab(history.location.pathname);
    }, [history.location.pathname]);

    const getSelectedIndex = (tab: string) => {
        switch (tab) {
            case ActiveTab.Profile:
                return 0;
            case ActiveTab.Offer:
                return 1;
            case ActiveTab.Acceptance:
                if (user.hasOffers && user.hasAcc) return 2;
                if (user.hasAcc && !user.hasOffers) return 1;
                if (user.hasOffers && !user.hasAcc) return 1;
                break;
            case ActiveTab.Notification:
                if (user.hasOffers && !user.hasAcc) return 2;
                if (user.hasAcc && !user.hasOffers) return 2;
                if (!user.hasOffers && !user.hasAcc) return 1;
                return 3;
            default:
                return 0;
        }
    };

    const getSelectedTab = (index: number) => {
        switch (index) {
            case 0:
                return ActiveTab.Profile;
            case 1:
                if (user.hasOffers) {
                    return ActiveTab.Offer;
                }
                if (user.hasAcc) {
                    return ActiveTab.Acceptance;
                }
                return ActiveTab.Notification;
            case 2:
                if (user.hasOffers && user.hasAcc) {
                    return ActiveTab.Acceptance;
                }
                return ActiveTab.Notification;
            case 3:
                return ActiveTab.Notification;
            default:
                return ActiveTab.Profile;
        }
    };

    return (
        <div>
            <div className={accountStyles.bg}>
                <div className={accountStyles.header}>
                </div>
            </div>

            {user &&
              <Tabs
                selectedIndex={getSelectedIndex(activeTab)}
                onSelect={index => {
                    const newTab = getSelectedTab(index);
                    setActiveTab(newTab);
                    history.push(newTab);
                }}
                className={accountStyles.tabs}
              >
                <div className={accountStyles.overflow}>
                  <TabList className={accountStyles.react_tabs}>
                    <Tab key="profile"
                         className={`${accountStyles.react_tabs__tab} ${activeTab === ActiveTab.Profile && accountStyles.react_tabs__tab_selected}`}>
                      <Link to="/account/profile" className={accountStyles.link_account}>
                          {t('accountPage.tabs.profile')}
                      </Link>
                    </Tab>
                      {user.hasOffers &&
                        <Tab key="My offers"
                             className={`${accountStyles.react_tabs__tab} ${activeTab === ActiveTab.Offer && accountStyles.react_tabs__tab_selected}`}>
                          <Link
                            to="/account/offers"
                            className={accountStyles.link_account}>
                              {t('accountPage.tabs.offer')}
                          </Link>
                        </Tab>}
                      {user.hasAcc &&
                        <Tab key="Acceptances"
                             className={`${accountStyles.react_tabs__tab} ${activeTab === ActiveTab.Acceptance && accountStyles.react_tabs__tab_selected}`}>
                          <Link
                            to="/account/acceptances"
                            className={accountStyles.link_account}>
                              {t('accountPage.tabs.acceptance')}
                          </Link>
                        </Tab>}
                    <Tab key="Messages"
                         className={`${accountStyles.react_tabs__tab} ${activeTab === ActiveTab.Notification && accountStyles.react_tabs__tab_selected}`}>
                      <Link
                        to="/account/notifications"
                        className={accountStyles.link_account}>
                          {t('accountPage.tabs.notification')}
                      </Link>
                    </Tab>
                  </TabList>
                </div>

                <TabPanel>
                  <UserProfile />
                </TabPanel>
                  {user.hasOffers &&
                    <TabPanel>
                      <MyOffersAndAcceptancesForm />
                    </TabPanel>}
                  {user.hasAcc &&
                    <TabPanel>
                      <Acceptances />
                    </TabPanel>}
                <TabPanel className={accountStyles.tab_panel_notification}>
                  <NotificationPage />
                </TabPanel>
              </Tabs>}
        </div>
    );
};
export default Account;