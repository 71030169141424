import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import notificationOperations from './notificationOperations';

export type NotificationType = {
    id: number;
    notificationType: string;
    notificationContext: string;
    createdOn: string;
    read: string;
}

export const notificationsAdapter = createEntityAdapter<NotificationType>();

export const updateNotificationAdapter = createEntityAdapter<NotificationType>();

interface InitialState {
    notifications: any;
    isLoading: boolean;
    unreadCount: any;
    updateNotification: any;
    notificationCount: any;
    totalPages: any;
    notificationAcceptanceId: any;
}

const initialState: InitialState = {
    // notification: [],
    notifications: notificationsAdapter.getInitialState(),
    isLoading: false,
    unreadCount: null,
    updateNotification: updateNotificationAdapter.getInitialState(),
    notificationCount: null,
    totalPages: null,
    notificationAcceptanceId: null,
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        clearNotifications: (state, {payload}) => {
            notificationsAdapter.removeAll(state.notifications);
        },
        clearUnreadCount: (state, {payload}) => {
            state.unreadCount = null;
        },
        setNotificationAcceptanceId: (state, {payload}) => {
            state.notificationAcceptanceId = payload;
        },
        removeNotification: (state, {payload}) => {
            notificationsAdapter.removeOne(state.notifications, payload);
        },
    },
    extraReducers: builder => {
        builder.addCase(
            notificationOperations.getNotifications.pending,
            (state, {payload}) => {
                state.isLoading = true;
            },
        );
        builder.addCase(
            notificationOperations.getNotifications.rejected,
            (state, {payload}) => {
                state.isLoading = false;
            },
        );
        builder.addCase(
            notificationOperations.getNotifications.fulfilled,
            (state, {payload}) => {
                notificationsAdapter.setAll(state.notifications, payload.elements);
                state.notificationCount = payload.totalElements;
                state.totalPages = payload.totalPages;
                state.isLoading = false;
            },
        );
        builder.addCase(
            notificationOperations.getUnreadCountNotifications.fulfilled,
            (state, {payload}) => {
                state.unreadCount = payload.count;
            },
        );
        builder.addCase(
            notificationOperations.updateNotification.fulfilled,
            (state, {payload}) => {
                const {id, ...changes} = payload;
                updateNotificationAdapter.updateOne(state.updateNotification, {id, changes});
            },
        );
        builder.addCase(
            notificationOperations.deleteNotification.fulfilled,
            (state, {payload}) => {
                notificationsAdapter.removeOne(state.notifications, payload);
            },
        );
    },
});

export default notificationSlice;