import React, {ChangeEvent} from 'react';
import {FieldValues, UseFormRegister} from 'react-hook-form';
import {FieldErrors} from 'react-hook-form/dist/types/errors';
import {useTranslation} from 'react-i18next';

interface Props {
    register: UseFormRegister<FieldValues>,
    errors: FieldErrors,
    constructionYear?: string | number;
    floor?: string;
    floors?: string;
}

const BuildingBlock = ({register, errors, constructionYear, floors, floor}: Props) => {

    const {t} = useTranslation();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        let inputValue = event.target.value;
        inputValue = inputValue.slice(0, 4);
        event.target.value = inputValue;
    };

    const handleChangeArea = (event: ChangeEvent<HTMLInputElement>) => {
        let inputValue = event.target.value;
        if (inputValue.length > 3) {
            inputValue = inputValue.slice(0, 3);
            event.target.value = inputValue;
        }
    };

    return (
        <>
            <h4 className="mb-3">{t('createNewOfferPage.building.title')}</h4>
            <div
                className="flex-row flex-row__create jcsa mb-2">
                <div className="lbl-apartment">{t('createNewOfferPage.building.constructionYear')}</div>
                <div>
                    <input
                        type="number"
                        id="constructionYear"
                        defaultValue={constructionYear ? constructionYear : ''}
                        className={
                            errors.constructionYear
                                ? 'error-inputs'
                                : 'inputs_new_offer'
                        }
                        {...register('constructionYear', {
                            onChange: handleChange,
                            required: t('formErrors.required').toString(),
                            min: {
                                value: 0,
                                message: t('formErrors.minLength').toString(),
                            },
                        })}
                    />
                    {errors.constructionYear ? (
                        <p className="error-message">
                            {errors.constructionYear.message}
                        </p>
                    ) : (
                        <p></p>
                    )}
                </div>
            </div>
            <div className="">
                <div
                    className="flex-row flex-row__create jcsa mb-2">
                    <div className="lbl-apartment">{t('createNewOfferPage.building.floor')}</div>
                    <div>
                        <input
                            type="number"
                            id="floor"
                            defaultValue={floor ? floor : ''}
                            className={
                                errors.floor
                                    ? 'error-inputs'
                                    : 'inputs_new_offer'
                            }
                            {...register('floor', {
                                required: t('formErrors.required').toString(),
                                onChange: handleChangeArea,
                                min: {
                                    value: 0,
                                    message: t('formErrors.minLength').toString(),
                                },
                            })}
                        />
                        {errors.floor ? (
                            <p className="error-message">{errors.floor.message}</p>
                        ) : (
                            <p></p>
                        )}
                    </div>
                </div>

                <div
                    className="flex-row flex-row__create mb-2">
                    <div className="lbl-apartment">{t('createNewOfferPage.building.floors')}</div>
                    <div>
                        <input
                            type="number"
                            id="floors"
                            defaultValue={floors ? floors : ''}
                            className={
                                errors.floors
                                    ? 'error-inputs'
                                    : 'inputs_new_offer'
                            }
                            {...register('floors', {
                                required: t('formErrors.required').toString(),
                                onChange: handleChangeArea,
                                min: {
                                    value: 0,
                                    message: t('formErrors.minLength').toString(),
                                },
                            })}
                        />
                        {errors.floors ? (
                            <p className="error-message">{errors.floors.message}</p>
                        ) : (
                            <p></p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default BuildingBlock;