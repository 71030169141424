import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import qs from 'qs';
import OffersForm from '../../components/OffersForm/OffersForm';
import {
    FilterType,
    OfferElementsType, ResponseRefreshUserSuccessActionPayloadType,
} from '../../models/generalTypes';
import filterSelectors from '../../features/filter/filterSelectors';
import filterOperations from '../../features/filter/filterOperations';
import geolocationSlice from '../../features/geolocation/geolocationSlice';
import offersSlice from '../../features/offers/offersSlice';
import filterSlice from '../../features/filter/filterSlice';
import userOperations from '../../features/user/userOperations';
import FilterSmartSearchForm from '../../components/FilterOffersPage/FilterSmartSearchForm/FilterSmartSearchForm';
import FilterApplied from '../../components/FilterOffersPage/FilterApplied/FilterApplied';
import LookingRentTabs from '../../components/LookingRentTabs/LookingRentTabs';
import userSelectors from '../../features/user/userSelectors';

import Sort from '../../components/Acceptances/enums/Sort';

import s from './SearchPage.module.scss';

export enum Type {
    Rent = 'RENT',
    Looking = 'LOOKING_FOR_RENT'
}

const SearchPage: React.FC = () => {

    const dispatch = useDispatch();

    const filter = useSelector(filterSelectors.getFilter) as FilterType;

    const filteredOffers: Array<OfferElementsType> = useSelector(
        filterSelectors.getFilteredOffers,
    );

    const type = useSelector(filterSelectors.getType);

    const history = useHistory();

    useEffect(() => {
        const parsed = qs.parse(history.location.search.substring(1), {
            delimiter: '&',
        });

        const actualFilter = {
            ...parsed,
            rooms: parsed.rooms !== undefined ? parsed.rooms.toString().split(',') : parsed.rooms,
            facilities: parsed.facilities !== undefined ? parsed.facilities.toString().split(',') : parsed.facilities,
        } as FilterType;

        const filtersFromQuery: FilterType | any = {
            type: actualFilter.type,
            city: actualFilter.city,
            facilities: actualFilter.facilities,
            animalPermission: actualFilter.animalPermission,
            rooms: actualFilter.rooms,
            areaFrom: actualFilter.areaFrom,
            areaTo: actualFilter.areaTo,
            floorFrom: actualFilter.floorFrom,
            floorTo: actualFilter.floorTo,
            priceFrom: actualFilter.priceFrom,
            priceTo: actualFilter.priceTo,
            buildingFloorFrom: actualFilter.buildingFloorFrom,
            buildingFloorTo: actualFilter.buildingFloorTo,
            currency: actualFilter.currency,
            constructionYearFrom: actualFilter.constructionYearFrom,
            constructionYearTo: actualFilter.constructionYearTo,
        };

        Object.keys(filtersFromQuery).forEach((key) => {
            if (filtersFromQuery[key] === undefined) {
                delete filtersFromQuery[key];
            }
        });

        if (Object.keys(filtersFromQuery).length !== 0) {
            dispatch(offersSlice.actions.setOfferIdNull());
            dispatch(geolocationSlice.actions.setHoverNull());
            dispatch(filterSlice.actions.setFilter(filtersFromQuery));
        }
    }, []);

    useEffect(() => {
        const sanitizeValue = (value: string | number | null | undefined, defaultValue = '0') => {
            return value === '' || value === undefined || value === null ? defaultValue : value;
        };

        const query = {
            currency: filter.currency,
            country: filter.country,
            facilities: filter.facilities && filter.facilities.length > 0 ? filter.facilities.toString() : '0-0',
            city: filter.city,
            state: filter.state,
            animalPermission: filter.animalPermission,
            areaFrom: sanitizeValue(filter.areaFrom),
            areaTo: sanitizeValue(filter.areaTo),
            priceFrom: sanitizeValue(filter.priceFrom),
            priceTo: sanitizeValue(filter.priceTo),
            rooms: filter.rooms && filter.rooms.length > 0 ? filter.rooms.toString() : '0-0',
            floorFrom: sanitizeValue(filter.floorFrom),
            floorTo: sanitizeValue(filter.floorTo),
            buildingFloorFrom: sanitizeValue(filter.buildingFloorFrom),
            buildingFloorTo: sanitizeValue(filter.buildingFloorTo),
            constructionYearFrom: sanitizeValue(filter.constructionYearFrom),
            constructionYearTo: sanitizeValue(filter.constructionYearTo),
        };

        const finalValues = Object.fromEntries(
            Object.entries(query).filter(
                ([key, value]) =>
                    value !== '' && value !== 'undefined' && value !== 'null' &&
                    value !== 'false' && value !== undefined && value !== null &&
                    value !== '0' && value !== '0-0',
            ),
        );

        const toFilter = qs.stringify(finalValues, {
            delimiter: '&',
            arrayFormat: 'indices',
        });

        history.push({
            pathname: 'offers',
            search: toFilter,
        });
    }, [filter]);

    const [sort, setSort] = useState({
        sort: Sort.Created,
    });
    const serialSortSettings = JSON.stringify(sort);
    const key = 'offers_page_sort';
    sessionStorage.setItem(key, serialSortSettings);

    const settingsSort = JSON.parse(sessionStorage.getItem(key) || '');

    const user = useSelector(
        userSelectors.getUserProfile,
    ) as ResponseRefreshUserSuccessActionPayloadType;

    useEffect(() => {
        if (type === Type.Rent) {
            dispatch(filterSlice.actions.clearFilteredOffers(filteredOffers));
            dispatch(filterOperations.getFilteredOffers({filter, size: 10, page: 0, sort: settingsSort.sort}));
            user && dispatch(userOperations.getUserOffers({page: 0, size: 10}));
        }
    }, [filter, type]);

    useEffect(() => {
        dispatch(filterSlice.actions.setType(Type.Rent));
    }, []);

    return (
        <div>
            <div className={s.flex_container}>
                <LookingRentTabs />
                <FilterSmartSearchForm />
                <FilterApplied />
                <OffersForm filteredOffers={filteredOffers} setSort={setSort} />
            </div>
        </div>
    );
};

export default SearchPage;