import React, {useEffect} from 'react';
import useWindowSize from '../../hooks/useWindowSize';

interface Props {
    styles?: string;
    children: React.ReactNode;
    stickyStyles: string;
}

const HeaderBar: React.FC<Props> = ({children, styles, stickyStyles}) => {

    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    }, []);

    const isSticky = (e: Event) => {
        const header = document.querySelector('.header-section');
        if (header) {
            const scrollTop = window.scrollY;
            scrollTop >= 100 ? header.classList.add(stickyStyles) : header.classList.remove(stickyStyles);
        }
    };

    const {isScreenResolutionMobile} = useWindowSize();

    return (
        <>
            <header className={`${styles} header-section ${!isScreenResolutionMobile ? 'd-none d-xl-block' : ''}`}>
                {children}
            </header>
        </>
    );
};

export default HeaderBar;