import React from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import LoaderButtonFormService from '../../common/Button/loaderButtonFormService/LoaderButtonFormService';
import {emailPattern} from '../../pattern/formPattern';
import {useDispatch, useSelector} from 'react-redux';
import userSelectors from '../../features/user/userSelectors';
import {ResponseRefreshUserSuccessActionPayloadType} from '../../models/generalTypes';
import feedbackOperation from '../../features/feedback/feedbackOperation';
import {useHistory} from 'react-router-dom';

import LoaderColor from '../../common/Button/enum/LoaderColor';

import picture from '../../assets/icons/notFound/picture.svg';

import inputStyles from '../../styles/inputStyles.module.scss';
import smthWentWrongStyles from './SmthWentWrong.module.scss';

const SmthWentWrong = () => {

    const {t} = useTranslation();

    const {
        register,
        formState: {errors},
        handleSubmit,
        watch,
    } = useForm();

    const dispatch = useDispatch();

    const history = useHistory();

    const submit = async () => {
        const message = watch('message');
        await dispatch(feedbackOperation.provideFeedback({email: user.email, message: message}));
        history.push('/');
    };

    const user = useSelector(
        userSelectors.getUserProfile,
    ) as ResponseRefreshUserSuccessActionPayloadType;

    return (
        <div className={smthWentWrongStyles.main}>
            <div className={smthWentWrongStyles.photo_block}>
                <img src={picture} alt="picture" className={smthWentWrongStyles.picture} />
            </div>
            <div className={smthWentWrongStyles.text_block}>
                <h1 className={smthWentWrongStyles.text}>
                    {t('wrong.description')}
                </h1>
            </div>
            <form onSubmit={handleSubmit(submit)} className={smthWentWrongStyles.form}>
                <div className={smthWentWrongStyles.form_main}>
                    <input
                        type="text"
                        className={errors.email ? inputStyles.input_error : inputStyles.input}
                        placeholder={t('wrong.email')}
                        defaultValue={user && user.email}
                        {...register('email', {
                            required: t('formErrors.required').toString(),
                            pattern: {
                                value: emailPattern,
                                message: t('formErrors.invalidEmail'),
                            },
                            minLength: {
                                value: 2,
                                message: t('formErrors.minLength'),
                            },
                        })}
                    />
                    <textarea
                        {...register('message', {
                            required: t('formErrors.required').toString(),
                        })}
                        placeholder={t('wrong.message')}
                        className={errors.message ? inputStyles.message_error : inputStyles.message}
                    />
                    <LoaderButtonFormService buttonContent={t('wrong.submit')}
                                             buttonClassName={smthWentWrongStyles.form_btn}
                                             submit={handleSubmit(submit)}
                                             color={LoaderColor.White} />
                </div>
            </form>
        </div>
    );
};

export default SmthWentWrong;