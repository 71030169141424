import React, {Suspense, useEffect, useState} from 'react';
import {Switch, Route, Redirect, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {ToastContainer} from 'react-toastify';
import HomePage from '../pages/HomePage/HomePage';
import AccountPage from '../pages/AccountPage/Account';
import OfferPage from '../pages/OfferPage/OfferPage';
import CreateNewOffer from '../pages/CreateNewOffer/CreateNewOffer';
import NotFoundPage from '../pages/NotFound/NotFound';
import SearchPage from '../pages/SearchPage/SearchPage';
import UpdateOfferPage from '../pages/UpdateOffer/UpdateOfferPage';
import Footer from './Footer/Footer';
import authSelectors from '../features/auth/authSelectors';
import ScrollToTopButton from './ScrollToTopButton/ScrollToTopButton';
import Navigation from './Navigation/Navigation';
import userSlice from '../features/user/userSlice';
import UserProfile from './UserProfile/UserProfile';
import MyOffersAndAcceptancesForm from './MyOffersAndAcceptancesForm/MyOffersAndAcceptancesForm';
import Acceptances from './Acceptances/MyAcceptancesPage/Acceptances';
import NotificationPage from './Notification/NotificationPage';
import axios from 'axios';
import authSlice from '../features/auth/authSlice';
import AuthModal from './Authentification/AuthModal/AuthModal';
import ContactUs from '../pages/ContactUs/ContactUs';
import SmthWentWrong from '../pages/SmthWentWrong/SmthWentWrong';
import PrivacyPolicyPage from '../pages/PrivacyPolicy/PrivacyPolicyPage';
import NavigationPanel from './Navigation/NavigationPanel';
import SubscriptionPage from '../pages/SubscriptionPage/SubscriptionPage';
import CreateOfferButton from './Buttons/CreateOfferButton';
import {ErrorBoundary} from 'react-error-boundary';

import 'react-toastify/dist/ReactToastify.css';
import appStyles from './App.module.scss';

const App: React.FC = () => {

    const [isModalOpen, toggleModal] = useState(false);

    const history = useHistory();

    axios.interceptors.response.use(
        (response: any) => {
            return response;
        },
        async (error: {response: {status: number;};}) => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(authSlice.actions.setAuthTokenNull(''));
                await history.push('/');
                await toggleModal(!isModalOpen);
            }
            return Promise.reject(error);
        },
    );

    const isAuthenticated = useSelector(authSelectors.getIsAuthenticated);

    const token = useSelector(authSelectors.getToken);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isAuthenticated && token !== null) {
            // dispatch(userOperations.getUserProfile());
        } else {
            dispatch(userSlice.actions.logoutUser);
        }
    }, [isAuthenticated, token]);

    return (
        <>
            <div
                className={
                    history.location.pathname === '/offers'
                        ? ''
                        : appStyles.main__content__container
                }
            >
                <Suspense fallback={<></>}>
                    <AuthModal isAuthModalOpen={isModalOpen} toggleAuthModal={toggleModal} />
                    {history.location.pathname === '/' ? <NavigationPanel /> : <Navigation />}
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        toastClassName={appStyles.toast_custom}
                        draggable
                        pauseOnHover
                        limit={3}
                        theme="light"
                    />
                    <ErrorBoundary fallback={<SmthWentWrong />}>
                        <Switch>
                            <Route exact path="/" component={HomePage} />
                            <Route path="/notfound" component={NotFoundPage} />
                            <Route path="/wrong" component={SmthWentWrong} />
                            <Route path="/contact-us" component={ContactUs} />
                            <Route path="/privacy" component={PrivacyPolicyPage} />
                            <Route path="/create" component={CreateNewOffer} />
                            <Route exact path="/offers/:id" component={OfferPage} />
                            <Route exact path="/update/:id" component={UpdateOfferPage} />
                            <Route exact path="/offers" component={SearchPage} />
                            <Route exact path="/subscriptions" component={SubscriptionPage} />
                            <Route path="/:activeTab" component={AccountPage} />
                            <Route exact path="/account/profile" component={UserProfile} />
                            <Route exact path="/account/offers" component={MyOffersAndAcceptancesForm} />
                            <Route exact path="/account/acceptances" component={Acceptances} />
                            <Route exact path="/account/notifications" component={NotificationPage} />
                            <Redirect to="/" />
                        </Switch>
                    </ErrorBoundary>
                    <Footer />
                    <ScrollToTopButton />
                    <CreateOfferButton />
                </Suspense>
            </div>
        </>
    );
};
export default App;