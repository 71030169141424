import React, {useEffect, useState} from 'react';

import popupStyles from './Popup.module.scss';
import {useHistory} from 'react-router-dom';

interface PopupProps {
    title: string;
    text: string;
    offerId: number;
}

const Popup: React.FC<PopupProps> = ({title, text, offerId}) => {

    const [isPopupVisible, setPopupVisible] = useState(false);

    const [textWidth, setTextWidth] = useState<number>(0);
    const maxWidth = 400;

    useEffect(() => {
        const textElement = document.getElementById(title);
        if (textElement) {
            setTextWidth(textElement.offsetWidth);
        }
    }, []);

    const history = useHistory();

    return (
        <>
            <div className={popupStyles.title_m}>
                <div className={popupStyles.title_container}>
                    <div className={popupStyles.title}
                         id={title}
                         onClick={() => history.push(`/offers/${offerId}`)}
                         onMouseEnter={() => setPopupVisible(true)}
                         onMouseLeave={() => setPopupVisible(false)}>
                        {title}
                    </div>
                    {isPopupVisible && textWidth >= maxWidth &&
                      <div className={popupStyles.popup}>
                          {text}
                      </div>}
                </div>
            </div>
        </>
    );
};

export default Popup;