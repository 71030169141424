import React from 'react';
import DropDownCurrencyPrice from '../../FilterHomePage/DropDownCurrencyPrice/DropDownCurrencyPrice';
import DropdownLink from '../../FilterHomePage/DropDownRooms/DropdownLink';
import FilterPopUpForm from '../FilterPopUpForm/FilterPopUpForm';
import {useHistory} from 'react-router-dom';
import HeaderBar from '../../FilterHeader/HeaderBar';

import './FilterSmartSearchForm.scss';

const FilterSmartSearchForm: React.FC = () => {

    const history = useHistory();

    const isSubscriptionsPage = history.location.pathname === '/subscriptions';

    return (
        <HeaderBar styles={!isSubscriptionsPage ? 'wrapper' : 'wrapper_without_other'} stickyStyles="is_sticky">
            <div className="flex-row__filters">
                <div className="full_length">
                    <DropDownCurrencyPrice />
                </div>
                {!isSubscriptionsPage &&
                  <div className="full_length">
                    <DropdownLink />
                  </div>}
                {!isSubscriptionsPage &&
                  <div className="full_length">
                    <FilterPopUpForm />
                  </div>}
            </div>
        </HeaderBar>
    );
};

export default FilterSmartSearchForm;