import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Offer from '../../components/Offer/Offer';
import offersSelectors from '../../features/offers/offersSelectors';
import offersOperations from '../../features/offers/offersOperations';
import {OfferElementsType} from '../../models/generalTypes';
import {ThreeDots} from "react-loader-spinner";

import LoaderColor from "../../common/Button/enum/LoaderColor";

import './OfferPage.scss';

const OfferPage = () => {

    type ParamsType = {
        id: string;
    };

    const dispatch = useDispatch();

    const params: ParamsType = useParams();
    const {id} = params;

    useEffect(() => {
        dispatch(offersOperations.getOfferWithId(id));
    }, []);

    const offer: OfferElementsType = useSelector(offersSelectors.getOfferWithId);

    if (offer === null) {
        return <div className="loader_center">
            <ThreeDots
                height="80"
                width="80"
                radius="9"
                color={LoaderColor.Green}
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                visible={true}
            />
        </div>;
    }
    return <>{<Offer offer={offer}/>}</>;
};

export default OfferPage;