import {baseURL} from '../base';
import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';

const provideFeedback = createAsyncThunk(
    'feedback',
    async (cred: {email: string, message: string}) => {
        try {
            const {data} = await axios.post(`${baseURL}/admin/feedback`, cred);
            return data;
        } catch (error) {
            console.log('error', error);
        }
    },
);

const operations = {
    provideFeedback,
};

export default operations;