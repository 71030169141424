import React from 'react';
import {emailPattern, phonePattern} from '../../pattern/formPattern';
import LoaderButtonFormService from '../../common/Button/loaderButtonFormService/LoaderButtonFormService';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import useWindowSize from '../../hooks/useWindowSize';
import {useDispatch, useSelector} from 'react-redux';
import userSelectors from '../../features/user/userSelectors';
import {ResponseRefreshUserSuccessActionPayloadType} from '../../models/generalTypes';
import {useHistory} from 'react-router-dom';
import contactOperations from '../../features/contact/contactOperations';

import eMail from '../../assets/icons/homePage/mail.svg';

import LoaderColor from '../../common/Button/enum/LoaderColor';

import contactUsStyles from './ContactUs.module.scss';

const ContactUs = () => {

    const {t} = useTranslation();

    const history = useHistory();

    const {
        register,
        formState: {errors},
        handleSubmit,
    } = useForm();

    const user = useSelector(
        userSelectors.getUserProfile,
    ) as ResponseRefreshUserSuccessActionPayloadType;

    const dispatch = useDispatch();

    const submit = async (formValues: any) => {
        await dispatch(contactOperations.contactUs({
            email: formValues.email,
            message: formValues.message,
            phone: formValues.phone,
            name: formValues.name,
        }));
        history.push('/');
    };

    const {isScreenResolutionMobile} = useWindowSize();

    return (
        <div>
            <section className={contactUsStyles.main}>
                <h2 className={contactUsStyles.main_h}>
                    {t('contactUs.header')}
                    <br />{t('contactUs.subHeader')}
                </h2>
                <p className={contactUsStyles.main_p}>
                    {t('contactUs.text')} <br />{t('contactUs.subText')}
                </p>
                <div className={contactUsStyles.main_flex}>
                    <form onSubmit={handleSubmit(submit)} className={contactUsStyles.form}>
                        <input
                            type="text"
                            defaultValue={user && user.name}
                            {...register('name', {
                                required: t('formErrors.required').toString(),
                                minLength: {
                                    value: 2,
                                    message: t('formErrors.minLength'),
                                },
                            })}
                            className={errors.name ? contactUsStyles.main_input_error : contactUsStyles.main_input}
                            placeholder={t('contactUs.placeholder.name')}
                        />
                        <>
                            <input
                                type="text"
                                defaultValue={user && user.phone ? user.phone : '+380'}
                                className={errors.phone ? contactUsStyles.main_input_error : contactUsStyles.main_input}
                                {...register('phone', {
                                    pattern: {
                                        value: phonePattern,
                                        message: t('formErrors.pattern'),
                                    },
                                    required: t('formErrors.required').toString(),
                                })}
                                onInput={(e) => {
                                    const input = e.target as HTMLInputElement;
                                    input.value = input.value.replace(/[^+\d\s]/g, '');
                                }}
                                maxLength={16}
                                placeholder={t('contactUs.placeholder.phone')}
                            />
                            <input
                                type="text"
                                defaultValue={user && user.email}
                                className={errors.email ? contactUsStyles.main_input_error : contactUsStyles.main_input}
                                {...register('email', {
                                    required: t('formErrors.required').toString(),
                                    pattern: {
                                        value: emailPattern,
                                        message: t('formErrors.invalidEmail'),
                                    },
                                    minLength: {
                                        value: 2,
                                        message: t('formErrors.minLength'),
                                    },
                                })}
                                placeholder={t('contactUs.placeholder.email')}
                            />
                        </>
                        <textarea
                            className={errors.message ? contactUsStyles.main_message_error : contactUsStyles.main_message}
                            placeholder={t('contactUs.placeholder.message')}
                            {...register('message', {
                                required: t('formErrors.required').toString(),
                            })}
                        />
                        <br />
                        <LoaderButtonFormService buttonContent={t('contactUs.btn')}
                                                 buttonClassName={contactUsStyles.main_btn}
                                                 submit={handleSubmit(submit)}
                                                 color={LoaderColor.White}
                        />
                    </form>
                    {!isScreenResolutionMobile && <img src={eMail} alt="mail" className={contactUsStyles.img} />}
                </div>
            </section>
        </div>
    );
};

export default ContactUs;