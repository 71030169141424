import {createSlice} from '@reduxjs/toolkit';
import feedbackOperation from './feedbackOperation';
import {toast} from 'react-toastify';
import i18n from 'i18next';

const initialState = {

}

const FeedbackSlice = createSlice({
    name: 'feedback',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(
            feedbackOperation.provideFeedback.fulfilled,
            (state, action) => {
                toast.success(i18n.t('notification.messageSent'));
            },
        );
    }
})
export default FeedbackSlice;