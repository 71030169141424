import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import {FieldValues, UseFormRegister, UseFormSetValue, UseFormWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import useWindowSize from '../../../hooks/useWindowSize';


interface Props {
    register: UseFormRegister<FieldValues>,
    setValue: UseFormSetValue<FieldValues>,
    watch: UseFormWatch<FieldValues>,
    animalPermissionDefaultValue?: boolean,
    dateDefault?: Date | null | undefined,
}

const RuleBlock = ({register, setValue, watch, animalPermissionDefaultValue = false, dateDefault}: Props) => {

    const {t} = useTranslation();

    const {isScreenResolutionMobile} = useWindowSize();

    const todayDate = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(todayDate.getDate() + 1);

    const [startDate, setStart] = useState<Date | null | undefined>(tomorrow);

    const afterYear = todayDate.getUTCFullYear() + 1;

    todayDate.setFullYear(afterYear);

    const [isAllowed, setIsAllowed] = useState(animalPermissionDefaultValue);

    useEffect(() => {
        dateDefault !== undefined ? setStart(dateDefault) : setStart(tomorrow);
    }, []);

    useEffect(() => {
        setValue('animalPermission', isAllowed);
    }, [isAllowed]);

    useEffect(() => {
        setValue('availableFrom', startDate);
    }, [startDate]);

    const handleToggle = () => {
        setIsAllowed(!isAllowed);
    };

    return (
        <div>
            <h4 className="mb-4">{t('createNewOfferPage.rules.title')}</h4>

            <div className={isScreenResolutionMobile ? 'flex-column' : 'area-row'}>
                <div className="animal_block">{t('createNewOfferPage.rules.pets.name')}</div>
                <div className="toggle-container">
                    <label className="toggle-switch">
                        <input
                            type="checkbox"
                            value={isAllowed ? 'true' : 'false'}
                            checked={isAllowed}
                            {...register('animalPermission', {
                                validate: (value) => value !== null && value !== undefined,
                            })}
                            onChange={handleToggle}
                        />
                        <span className="slide"></span>
                    </label>
                </div>
            </div>
            <div className={isScreenResolutionMobile ? 'flex-column' : 'area-row mt-3'}>
                <div className="date_new_offer">{t('createNewOfferPage.date.description')}</div>
                <DatePicker
                    autoComplete="off"
                    name="availableFrom"
                    className="calendar"
                    selected={startDate}
                    onChange={(val: any) => {
                        setStart(val);
                    }}
                    dateFormat="dd.MM.yyyy"
                />
            </div>
        </div>
    );
};

export default RuleBlock;