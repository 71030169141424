import axios from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {FilterType} from '../../models/generalTypes';
import qs from 'qs';
import {baseURL} from '../base';

axios.defaults.baseURL = 'http://api.rentbox.com.ua';

export interface CredentialsFilteredOffers {
    filter: FilterType;
    size?: string | number;
    page: number;
    sort?: string;
}

const getFilteredOffers = createAsyncThunk(
    'filter/getFilteredOffers',
    async ({filter, size = 10, page, sort = 'createdOn,desc'}: CredentialsFilteredOffers) => {

        const MIN = '1';
        const MAX = '999999999';

        const query = `currency=${
            filter.currency
        }&country=${filter.country}&city=${filter.city}&state=${
            filter.state
        }&type=${(filter.type)
        }&animalPermission=${(filter.animalPermission)
        }&areaFrom=${
            filter.areaFrom === '' ||
            filter.areaFrom === undefined ||
            filter.areaFrom === null
                ? MIN
                : filter.areaFrom
        }&areaTo=${
            filter.areaTo === '' ||
            filter.areaTo === undefined ||
            filter.areaTo === null
                ? MAX
                : filter.areaTo
        }&priceFrom=${
            filter.priceFrom === '' ||
            filter.priceFrom === undefined ||
            filter.priceFrom === null
                ? MIN
                : filter.priceFrom
        }&priceTo=${
            filter.priceTo === '' ||
            filter.priceTo === undefined ||
            filter.priceTo === null
                ? MAX
                : filter.priceTo
        }&floorFrom=${
            filter.floorFrom === '' ||
            filter.floorFrom === undefined ||
            filter.floorFrom === null
                ? MIN
                : filter.floorFrom
        }&floorTo=${
            filter.floorTo === '' ||
            filter.floorTo === undefined ||
            filter.floorTo === null
                ? MAX
                : filter.floorTo
        }&buildingFloorFrom=${
            filter.buildingFloorFrom === '' ||
            filter.buildingFloorFrom === undefined ||
            filter.buildingFloorFrom === null
                ? MIN
                : filter.buildingFloorFrom
        }&buildingFloorTo=${
            filter.buildingFloorTo === '' ||
            filter.buildingFloorTo === undefined ||
            filter.buildingFloorTo === null
                ? MAX
                : filter.buildingFloorTo
        }&constructionYearFrom=${
            filter.constructionYearFrom === '' ||
            filter.constructionYearFrom === undefined ||
            filter.constructionYearFrom === null
                ? MIN
                : filter.constructionYearFrom
        }&constructionYearTo=${
            filter.constructionYearTo === '' ||
            filter.constructionYearTo === undefined ||
            filter.constructionYearTo === null
                ? MAX
                : filter.constructionYearTo
        }&`;

        const parsedQuery = qs.parse(query, {delimiter: '&'});

        type FilterTypeParsed = {
            animalPermission?: boolean | undefined | string;

            currency?: string | null | undefined;

            country?: string | number;

            city?: string;

            state?: string;

            areaFrom?: string | number | null;
            areaTo?: string | number | null;

            priceFrom?: string | number | null;
            priceTo?: string | number | null;

            rooms?: string | undefined;

            floorFrom?: string | number | null;
            floorTo?: string | number | null;

            buildingFloorFrom?: string | number | null;
            buildingFloorTo?: string | number | null;

            constructionYearFrom?: string | number | null;
            constructionYearFromTo?: string | number | null;
        };

        const updValues: FilterTypeParsed = Object.fromEntries(
            Object.entries(parsedQuery).filter(
                n =>
                    n[0] !== '' &&
                    n[1] !== 'undefined' &&
                    n[1] !== 'null' &&
                    n[1] !== undefined &&
                    n[1] !== 'false' &&
                    n[1] !== '' &&
                    n[1] !== null &&
                    n[1] !== '0' &&
                    n[1] !== '0-0' &&
                    n[1] !== '0-0 ',
            ),
        );

        const finalValues = {
            ...updValues,
        };

        const rooms = filter.rooms.map((item) => item.length > 1 ? item.split(',').map(room => `rooms=${room}`).join('&') : `rooms=${item}`);
        const toRooms = rooms.join('&');

        const facilities = filter.facilities.map((item) => item.length > 1 ? item.split(',').map(facility => `facilities=${facility}`).join('&') : `facilities=${item}`);
        const facilitiesFilter = facilities.join('&');

        const toFilter = qs.stringify(finalValues, {
                delimiter: '&',
                arrayFormat: 'indices',
            })
            + (toRooms !== '' ? `&${toRooms}` : '')
            + (facilitiesFilter !== '' ? `&${facilitiesFilter}` : '');

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        };

        const response = await axios.get(
            `${baseURL}/offers?page=${page}&size=${size}&${toFilter}&sort=${sort}`,
            config,
        );

        return response as any;
    },
);

export interface SmartSearch {
    phrase?: string;
    city?: string;
}

const getSmartSearchedOffers = createAsyncThunk(
    'auth/getSmartSearchedOffers',
    async ({phrase, city}: SmartSearch) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        };
        try {
            return await axios.get(`${baseURL}/smart-search/offers?phrase=${phrase}&city=${city}`, config);
        } catch (error) {
            console.log('error', error);
        }
    },
);

const operations = {
    getFilteredOffers,
    getSmartSearchedOffers,
};
export default operations;