import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import QuestionBlock from './questionBlock/QuestionBlock';
import {useDispatch, useSelector} from 'react-redux';
import filterSelectors from '../../features/filter/filterSelectors';
import {FilterType} from '../../models/generalTypes';
import {useHistory} from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize';
import SelectCityDropDown from '../../components/FilterHomePage/SelectCityDropDown/SelectCityDropDown';
import DropdownLink from '../../components/FilterHomePage/DropDownRooms/DropdownLink';
import DropDownCurrencyPrice from '../../components/FilterHomePage/DropDownCurrencyPrice/DropDownCurrencyPrice';
import {useForm} from 'react-hook-form';
import {emailPattern, phonePattern} from '../../pattern/formPattern';
import contactOperations from '../../features/contact/contactOperations';
import authSelectors from '../../features/auth/authSelectors';
import LoaderButtonFormService from '../../common/Button/loaderButtonFormService/LoaderButtonFormService';
import geolocationOperations from '../../features/geolocation/geolocationOperations';
import AuthModal from '../../components/Authentification/AuthModal/AuthModal';
import ScrollGif from './scrollGif/ScrollGif';

import describePhoto from '../../assets/icons/homePage/describe-photo.svg';
import first from '../../assets/icons/homePage/first.svg';
import second from '../../assets/icons/homePage/second.svg';
import third from '../../assets/icons/homePage/third.svg';
import fourth from '../../assets/icons/homePage/fourth.svg';
import rocket from '../../assets/icons/homePage/rocket.svg';
import eMail from '../../assets/icons/homePage/mail.svg';
import photo from '../../assets/icons/homePage/photo.svg';
import add from '../../assets/icons/createOfferBtn/add.svg';

import LoaderColor from '../../common/Button/enum/LoaderColor';
import WindowSize from '../../enums/WindowSize';

import homePageStyles from './HomePage.module.scss';

const HomePage = () => {

    const [isRent, setIsRent] = useState(false);

    const history = useHistory();

    const [isModalOpen, toggleModal] = useState(false);

    const {t} = useTranslation();

    const isAuthenticated = useSelector(authSelectors.getIsAuthenticated);

    const filter = useSelector(filterSelectors.getFilter) as FilterType;

    const [city, setCity] = useState({code: 'KBP', name: filter.city});

    const {isScreenResolutionMobile, windowSize} = useWindowSize();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(geolocationOperations.getCities());
    }, []);

    const {
        register,
        formState: {errors},
        handleSubmit,
        clearErrors,
        reset,
        watch,
    } = useForm();

    const getSelectWidth = () => {
        return windowSize.width < WindowSize.MD ? 310 : 238;
    };

    const submit = async () => {
        const name = watch('name');
        const phone = watch('phone');
        const email = watch('email');
        const message = watch('message');
        await dispatch(contactOperations.contactUs({name, phone, email, message}));
        reset({message: ''});
    };

    const [phone, setPhone] = useState('+380');

    const formatPhoneNumber = (input: string): string => {
        const cleaned = ('' + input).replace(/\D/g, '');

        const match = cleaned.match(/^(\d{1,3})(\d{0,2})(\d{0,3})(\d{0,4})$/);

        if (match) {
            return `+${match[1]}${match[2] ? ' ' + match[2] : ''}${match[3] ? ' ' + match[3] : ''}${match[4] ? ' ' + match[4] : ''}`;
        }

        return cleaned;
    };

    const changeHandler = (e: any) => {
        const {value} = e.target;
        setPhone(formatPhoneNumber(value));
        if (phonePattern.test(e.target.value)) {
            clearErrors('phone');
        }
    };

    return (
        <div className={homePageStyles.main}>

            <div className={homePageStyles.header_background}>
                <div className={homePageStyles.choice}>
                    <button onClick={() => setIsRent(false)}
                            className={`${homePageStyles.choice_btn} ${!isRent && homePageStyles.choice_btn__underline}`}>
                        {t('homePage.choice.looking')}
                    </button>
                    <button onClick={() => setIsRent(true)}
                            className={`${homePageStyles.choice_btn} ${isRent && homePageStyles.choice_btn__underline}`}>
                        {t('homePage.choice.rent')}
                    </button>
                </div>
                {isRent ?
                    <>
                        <h1 className={homePageStyles.header_background__text}>{t('homePage.header.renting.title')}
                            {isScreenResolutionMobile && <br />} <span
                                className={homePageStyles.header_background__text_without}>{t('homePage.header.renting.without')}</span>
                            <p>{t('homePage.header.renting.earn')}</p>
                        </h1>
                    </>
                    :
                    <>
                        <h1 className={homePageStyles.header_background__text}>{t('homePage.header.looking.title')}
                            {isScreenResolutionMobile && <br />} <span
                                className={homePageStyles.header_background__text_without}>{t('homePage.header.looking.without')}</span>
                        </h1>
                        <div className={homePageStyles.search_flex}>
                            <SelectCityDropDown city={city} setCity={setCity} />
                            <div className={homePageStyles.search_separator} />
                            <DropdownLink width={getSelectWidth()} borderRadius="100px" />
                            <div className={homePageStyles.search_separator} />
                            <DropDownCurrencyPrice width={getSelectWidth()} borderRadius="100px" />
                            {isScreenResolutionMobile && <div className={homePageStyles.search_separator} />}
                            <button
                                className={homePageStyles.search_btn}
                                onClick={() => history.push(`/offers?currency=UAH&city=${city.name}`)}
                            >
                                <div className={homePageStyles.search} />
                            </button>
                        </div>
                    </>}
                {!isScreenResolutionMobile &&
                  <>{isRent ?
                      (isAuthenticated ?
                          <button
                              onClick={() => history.push('/create')}
                              className={homePageStyles.start_btn}>
                              {t('homePage.btn.add')}
                              <img src={add} alt="add" className="add_svg" />
                          </button> :
                          <button
                              onClick={() => toggleModal(!isModalOpen)}
                              className={homePageStyles.start_btn}>
                              {t('homePage.btn.start')}
                          </button>)
                      :
                      <button
                          className={homePageStyles.start_btn}
                          onClick={() => history.push(`/offers?currency=UAH&city=${city.name}`)}>
                          {t('homePage.btn.show')}
                      </button>}
                  </>}
            </div>

            {isScreenResolutionMobile &&
              <>{isRent ?
                  (isAuthenticated ?
                      <button
                          onClick={() => history.push('/create')}
                          className={homePageStyles.start_btn}>
                          {t('homePage.btn.add')}
                          <img src={add} alt="add" className="add_svg" />
                      </button> :
                      <button
                          onClick={() => toggleModal(!isModalOpen)}
                          className={homePageStyles.start_btn}>
                          {t('homePage.btn.start')}
                      </button>)
                  :
                  <button
                      className={homePageStyles.start_btn}
                      onClick={() => history.push(`/offers?currency=UAH&city=${city.name}`)}>
                      {t('homePage.btn.show')}
                  </button>}
              </>}

            <section id="about" className={homePageStyles.describe}>
                <div className={homePageStyles.describe_flex}>
                    <img src={describePhoto} alt="describePhoto" className={homePageStyles.describe_photo} />
                    <span className={homePageStyles.describe_text}>
                        <h2 className={homePageStyles.describe_text_h}>{t('homePage.describe.title')}<span
                            className={homePageStyles.describe_text_h_green}>{t('homePage.describe.subTitle')}</span></h2>
                        <p className={homePageStyles.describe_text_p}>
                            {t('homePage.describe.textTitle')}
                        </p>
                        <p className={homePageStyles.describe_text_p}>
                            {t('homePage.describe.text')}
                        </p>
                    </span>
                </div>
            </section>

            <section id="advantages" className={homePageStyles.advantages}>
                <div className={homePageStyles.advantages_text}>
                    <h2 className={homePageStyles.advantages_text_h}>
                        {t('homePage.advantages.title')}
                    </h2>
                    <p className={homePageStyles.advantages_text_p}>
                        {t('homePage.advantages.text')}
                    </p>
                </div>
                <div className={homePageStyles.advantages_text_stairs}>
                    <div className={homePageStyles.advantages_text_stairs_span}>
                        <img src={first} alt="first" />
                        <h4 className={homePageStyles.advantages_text_stairs_h}>{t('homePage.advantages.textStairs.first.title')}</h4>
                        <div className={homePageStyles.advantages_text_stairs_line}></div>
                        <p className={homePageStyles.advantages_text_stairs_p}>{t('homePage.advantages.textStairs.first.text')}</p>
                    </div>
                    <div className={homePageStyles.advantages_text_stairs_span}>
                        <img src={second} alt="second" className={homePageStyles.advantages_text_stairs_img_second} />
                        <h4 className={homePageStyles.advantages_text_stairs_h}>{t('homePage.advantages.textStairs.second.title')}</h4>
                        <div className={homePageStyles.advantages_text_stairs_line}></div>
                        <p className={homePageStyles.advantages_text_stairs_p}>{t('homePage.advantages.textStairs.second.text')}</p>
                    </div>
                    <div className={homePageStyles.advantages_text_stairs_span}>
                        <img src={third} alt="third" className={homePageStyles.advantages_text_stairs_img_third} />
                        <h4 className={homePageStyles.advantages_text_stairs_h}>{t('homePage.advantages.textStairs.third.title')}</h4>
                        <div className={homePageStyles.advantages_text_stairs_line}></div>
                        <p className={homePageStyles.advantages_text_stairs_p}>{t('homePage.advantages.textStairs.third.text')}</p>
                    </div>
                    <div className={homePageStyles.advantages_text_stairs_span}>
                        <img src={fourth} alt="fourth" className={homePageStyles.advantages_text_stairs_img_fourth} />
                        <h4 className={homePageStyles.advantages_text_stairs_h}>{t('homePage.advantages.textStairs.fourth.title')}</h4>
                        <div className={homePageStyles.advantages_text_stairs_line}></div>
                        <p className={homePageStyles.advantages_text_stairs_p}>{t('homePage.advantages.textStairs.fourth.text')}</p>
                    </div>
                </div>
            </section>

            <div className={homePageStyles.reminder}>
                <img src={rocket} alt="rocket" />
                <div className={homePageStyles.reminder_text}>
                    <h3 className={homePageStyles.reminder_text_h}>{t('homePage.reminder.title')}</h3>
                    {isRent ?
                        <p className={homePageStyles.reminder_text_p}>
                            {t('homePage.reminder.text.renting')}
                        </p>
                        :
                        <p className={homePageStyles.reminder_text_p}>
                            {t('homePage.reminder.text.looking')}
                        </p>}
                    <p className={homePageStyles.reminder_text_p}>{t('homePage.reminder.text.come')}</p>
                </div>
                {isAuthenticated ?
                    <button
                        className={homePageStyles.reminder_btn}
                        onClick={() => history.push('/create')}
                    >
                        {t('homePage.btn.add')}
                        <img src={add} alt="add" className="add_svg" />
                    </button>
                    :
                    <button
                        className={homePageStyles.reminder_btn}
                        onClick={() => toggleModal(!isModalOpen)}
                    >
                        {t('homePage.btn.start')}
                    </button>}
            </div>

            <section id="steps" className={homePageStyles.steps}>
                <ScrollGif isRent={isRent} />
            </section>

            <div className={homePageStyles.questions}>
                <h2 className={homePageStyles.questions_h}>{t('homePage.questions.title')}
                    <br />{t('homePage.questions.subTitle')}</h2>
                <div className={homePageStyles.questions_flex}>
                    {!isScreenResolutionMobile &&
                      <div className={homePageStyles.questions_photo_block}>
                        <img src={photo} alt="photo" />
                        <p className={homePageStyles.questions_photo_block_p}>{t('homePage.questions.pc.text')}
                        </p>
                        <a href="#e_mail"
                           className={homePageStyles.questions_photo_block_btn}>{t('homePage.questions.pc.btn')}</a>
                      </div>}
                    <div className={homePageStyles.questions_question_block}>
                        <hr className={homePageStyles.questions_question_block_line} />
                        <QuestionBlock
                            number={t('homePage.questions.questionBlock.one.number')}
                            question={t('homePage.questions.questionBlock.one.question')}
                            answer={t('homePage.questions.questionBlock.one.answer')}
                        />
                        <QuestionBlock
                            number={t('homePage.questions.questionBlock.two.number')}
                            question={t('homePage.questions.questionBlock.two.question')}
                            answer={t('homePage.questions.questionBlock.two.answer')}
                        />
                        <QuestionBlock
                            number={t('homePage.questions.questionBlock.three.number')}
                            question={t('homePage.questions.questionBlock.three.question')}
                            answer={t('homePage.questions.questionBlock.three.answer')}
                        />
                        <QuestionBlock
                            number={t('homePage.questions.questionBlock.four.number')}
                            question={t('homePage.questions.questionBlock.four.question')}
                            answer={t('homePage.questions.questionBlock.four.answer')}
                        />
                    </div>
                </div>
            </div>

            <section id="e_mail" className={homePageStyles.e_mail}>
                <h2 className={homePageStyles.e_mail_h}>
                    {t('homePage.email.title')}
                    <br />{t('homePage.email.subTitle')}
                </h2>
                <p className={homePageStyles.e_mail_p}>
                    {t('homePage.email.text')} <br />{t('homePage.email.subText')}
                </p>
                <div className={homePageStyles.e_mail_flex}>
                    <form onSubmit={handleSubmit(submit)} className={homePageStyles.form}>
                        <input
                            type="text"
                            {...register('name', {
                                required: t('formErrors.required').toString(),
                                minLength: {
                                    value: 2,
                                    message: t('formErrors.minLength'),
                                },
                            })}
                            className={errors.name ? homePageStyles.e_mail_input_error : homePageStyles.e_mail_input}
                            placeholder={t('homePage.email.placeholder.name')}
                        />
                        {!isScreenResolutionMobile ?
                            <div className="flex-row gap-2">
                                <input
                                    className={errors.phone ? homePageStyles.e_mail_input_error : homePageStyles.e_mail_input}
                                    {...register('phone', {
                                        pattern: {
                                            value: phonePattern,
                                            message: t('formErrors.pattern'),
                                        },
                                        required: t('formErrors.required').toString(),
                                    })}
                                    value={phone}
                                    maxLength={16}
                                    onChange={changeHandler}
                                    placeholder={t('homePage.email.placeholder.phone')}
                                />
                                <input
                                    type="text"
                                    className={errors.email ? homePageStyles.e_mail_input_error : homePageStyles.e_mail_input}
                                    placeholder={t('homePage.email.placeholder.email')}
                                    {...register('email', {
                                        required: t('formErrors.required').toString(),
                                        pattern: {
                                            value: emailPattern,
                                            message: t('formErrors.invalidEmail'),
                                        },
                                        minLength: {
                                            value: 2,
                                            message: t('formErrors.minLength'),
                                        },
                                    })}
                                />
                            </div> :
                            <>
                                <input
                                    type="text"
                                    className={errors.phone ? homePageStyles.e_mail_input_error : homePageStyles.e_mail_input}
                                    {...register('phone', {
                                        pattern: {
                                            value: phonePattern,
                                            message: t('formErrors.pattern'),
                                        },
                                        required: t('formErrors.required').toString(),
                                    })}
                                    value={phone}
                                    maxLength={16}
                                    onChange={changeHandler}
                                    placeholder={t('homePage.email.placeholder.phone')}
                                />
                                <input
                                    type="text"
                                    className={errors.email ? homePageStyles.e_mail_input_error : homePageStyles.e_mail_input}
                                    {...register('email', {
                                        required: t('formErrors.required').toString(),
                                        pattern: {
                                            value: emailPattern,
                                            message: t('formErrors.invalidEmail'),
                                        },
                                        minLength: {
                                            value: 2,
                                            message: t('formErrors.minLength'),
                                        },
                                    })}
                                    placeholder={t('homePage.email.placeholder.email')}
                                />
                            </>}
                        <textarea
                            className={errors.message ? homePageStyles.e_mail_message_error : homePageStyles.e_mail_message}
                            placeholder={t('homePage.email.placeholder.message')}
                            {...register('message', {
                                required: t('formErrors.required').toString(),
                            })}
                        />
                        <br />
                        <LoaderButtonFormService buttonContent={t('homePage.email.btn')}
                                                 buttonClassName={homePageStyles.e_mail_btn}
                                                 submit={handleSubmit(submit)}
                                                 color={LoaderColor.White}
                        />
                    </form>
                    {!isScreenResolutionMobile && <img src={eMail} alt="mail" className={homePageStyles.email_img} />}
                </div>
            </section>
            <AuthModal isAuthModalOpen={isModalOpen} toggleAuthModal={toggleModal} />
        </div>
    );
};

export default HomePage;