import React, {useEffect, useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import {useHistory} from 'react-router-dom';
import {OfferElementsType} from '../../models/generalTypes';
import offersSelectors from '../../features/offers/offersSelectors';
import offersOperations from '../../features/offers/offersOperations';
import geolocationSelectors from '../../features/geolocation/geolocationSelectors';
import offersSlice from '../../features/offers/offersSlice';
import {useForm} from 'react-hook-form';
import useWindowSize from '../../hooks/useWindowSize';
import LoaderButtonFormService from '../../common/Button/loaderButtonFormService/LoaderButtonFormService';
import {ImageList} from '@mui/material';
import PhotoManager from '../AddNewOffer/photos/PhotoManager';
import AddressBlock from '../AddNewOffer/address/AddressBlock';
import ApartmentBlock from '../AddNewOffer/apartment/ApartmentBlock';
import FacilityBlock from '../AddNewOffer/falcility/FacilityBlock';
import BuildingBlock from '../AddNewOffer/building/BuildingBlock';
import RuleBlock from '../AddNewOffer/rule/RuleBlock';

import LoaderColor from '../../common/Button/enum/LoaderColor';

import clip from '../../assets/icons/clip.svg';
import plug from '../../assets/photos/plug.png';

import './UpdateOffer.scss';
import '../AddNewOffer/AddNewOffer.scss';

type Props = {
    offerWithId: OfferElementsType;
    id: string;
};

const UpdateOffer: React.FC<Props> = ({id, offerWithId}) => {

    const dispatch = useDispatch();

    const {
        register,
        setValue,
        handleSubmit,
        setError,
        watch,
        formState: {errors},
    } = useForm();

    const {t} = useTranslation();

    const history = useHistory();

    const [petDeposit, setPetDeposit] = useState(watch('animalPermission'));

    useEffect(() => {
        setPetDeposit(watch('animalPermission'));
    }, [watch('animalPermission')]);

    const getCityCode = useSelector(offersSelectors.getCityWhenCreate);

    const [geo, setGeolocation] = useState('');

    const getAddress = useSelector(geolocationSelectors.getAddress);

    const offerPhotos = useSelector(offersSelectors.photosWhenOfferUpdate);

    const photoFiles: File[] = useSelector(offersSelectors.photosFilesWhenUpdateOffer);

    const [finishPhotos, setFinishPhotos] = useState([] as any);

    const getPhotos = useCallback(() => {
            const photosAdded: any = [];
            offerWithId.photos.forEach(item => {
                photosAdded.push(item.photoLocation);
            });
            for (let i = 0; i < Math.min(10, offerPhotos.length); i++) {
                const otherPhotos = offerPhotos[i];
                photosAdded.push(otherPhotos);
            }
            const remainingSlots = Math.max(0, 10 - photosAdded.length);
            photosAdded.push(...Array(remainingSlots).fill(plug));

            return photosAdded.slice(0, 10);
        }, [offerPhotos, offerWithId.photos],
    );

    useEffect(() => {
        setFinishPhotos(getPhotos());
    }, [offerPhotos, offerWithId]);

    const uploadMultipleFiles = (e: any) => {
        const files = Array.from(e.target.files || []);
        files.map(file => dispatch(offersSlice.actions.setPhotosFilesWhenUpdateOffer(file)));
        const previews: string[] = [];
        for (let i = 0; i < files.length; i++) {
            const file: any = files[i];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                previews.push(reader.result as string);
                if (previews.length === files.length) {
                    dispatch(offersSlice.actions.setPhotosWhenOfferUpdate(previews));
                }
            };
        }
    };

    const isOfferUpdated: boolean = useSelector(offersSelectors.isOfferUpdated);

    const submit = async (formValues: any) => {
        const photos: Array<any> = [photoFiles];

        photoFiles.length !== 0 && id !== null && await dispatch(offersOperations.uploadOfferPhotos({
            id: id,
            photos: photos,
        }));

        const addressString = getAddress[0].formattedAddress;

        const updValues = {
            ...formValues,
            state: offerWithId.state,
            expiresOn: offerWithId.expiresOn,
            availableFrom: formValues.availableFrom,
            animalPermission: formValues.animalPermission,
            communalIncluded: formValues.communalIncluded,
            petDeposit: formValues.animalPermission !== 'false' ? formValues.petDeposit : 0,
            geolocation: {
                address: addressString,
                city: getCityCode.name,
                countryCode: 'UA',
                latitude: getAddress[0].latitude,
                longitude: getAddress[0].longitude,
            },
        };

        await dispatch(offersOperations.updateOfferWithId({offerId: id, values: updValues}));
    };

    useEffect(() => {
        return () => {
            dispatch(offersSlice.actions.setIsOfferUpdated(false));
        };
    }, []);

    useEffect(() => {
        isOfferUpdated && history.push(`/offers/${offerWithId.id}`);
    }, [isOfferUpdated]);

    const [communalIncludedCheckBoxes, setCommunalIncludedCheckBoxes] = useState([false, false]);

    useEffect(() => {
        offerWithId.communalIncluded ?
            setCommunalIncludedCheckBoxes([false, true]) :
            setCommunalIncludedCheckBoxes([true, false]);
    }, []);

    const handleCommunalCheckboxClick = (index: number) => {
        setCommunalIncludedCheckBoxes(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            for (let i = 0; i < newState.length; i++) {
                if (index !== i) {
                    newState[i] = false;
                }
            }
            return newState;
        });
    };

    const {isScreenResolutionMobile} = useWindowSize();

    const convertToDate = (value: string | Date): Date | null | undefined => {
        if (value instanceof Date) {
            return value;
        }

        const parsedDate = new Date(value);
        if (!isNaN(parsedDate.getTime())) {
            return parsedDate;
        }

        return null;
    };

    return (
        <div className={isScreenResolutionMobile ? 'main_update w-100' : ''}>
            <form onSubmit={handleSubmit(submit)}>
                <div className="flex-wrapper">
                    <div className="white-box-update">
                        <AddressBlock geo={geo} setGeolocation={setGeolocation} register={register} errors={errors}
                                      defaultAddress={offerWithId.geolocation.address}
                                      defaultCity={offerWithId.geolocation.city} setValue={setValue}
                                      setError={setError} watch={watch} />
                    </div>
                    <div className="white-box-update">
                        <BuildingBlock register={register} errors={errors}
                                       constructionYear={offerWithId.constructionYear} floor={offerWithId.floor}
                                       floors={offerWithId.floors} />
                    </div>
                    <div className="white-box-update">
                        <ApartmentBlock register={register} errors={errors} offerWithId={offerWithId} />
                    </div>
                    <div className="white-box-update">
                        <FacilityBlock register={register} errors={errors} facilities={offerWithId.facilities}
                                       setValue={setValue} />
                    </div>
                    <div className="white-box-update">
                        <RuleBlock register={register} setValue={setValue} watch={watch}
                                   animalPermissionDefaultValue={offerWithId.animalPermission}
                                   dateDefault={convertToDate(offerWithId.availableFrom)} />
                    </div>
                    <div className="white-box-update">
                        <h4>{t('createNewOfferPage.description.title')}</h4>
                        <div className={isScreenResolutionMobile ? 'flex-column' : 'flex-row'}>
                            <div className="lbl-apartment mt-3 mr_title">{t('createNewOfferPage.description.header')}*
                            </div>
                            <input
                                defaultValue={offerWithId.title}
                                className={
                                    errors.title
                                        ? 'error'
                                        : 'address'
                                }
                                type="text"
                                {...register('title', {
                                    required: 'this is a required',
                                })}
                            />
                            {errors.title ? (
                                <p className="error-message">{errors.title.message}</p>
                            ) : (
                                <p></p>
                            )}
                        </div>

                        <div className={isScreenResolutionMobile ? 'flex-column' : 'flex-row'}>
                            <div className="descriptions">{t('createNewOfferPage.description.description')}*</div>
                            <textarea
                                defaultValue={offerWithId.description}
                                placeholder={t('createNewOfferPage.description.placeholder')}
                                className={
                                    errors.description
                                        ? 'error-input_description'
                                        : 'input-text-description'
                                }
                                {...register('description', {
                                    required: 'this is a required',
                                })}
                            />
                            {errors.description ? (
                                <p className="error-message">{errors.description.message}</p>
                            ) : (
                                <p></p>
                            )}
                        </div>
                    </div>

                    <div className="white-box-update">
                        <h4>{t('createNewOfferPage.photos.title')}</h4>

                        <p className="recommendation">
                            {t('createNewOfferPage.photos.description')}
                        </p>

                        <div className="photo_section">

                            {isScreenResolutionMobile ?
                                (finishPhotos.filter((image: string) => image !== plug).length !== 0 ?
                                    <>
                                        <ImageList sx={{width: '100%', height: 'auto'}} cols={2} rowHeight={100}
                                                   className="m-0 rounded-3">
                                            {finishPhotos.filter((image: string) => image !== plug).map((preview: string, index: number) => (
                                                <div className="image_list_item" key={index}>
                                                    <PhotoManager photo={preview} index={index} isUpdateOffer={true}
                                                                  offerWithIdPhotos={offerWithId.photos} offerId={id} />
                                                </div>
                                            ))}
                                        </ImageList>
                                        <div className="upload_btn">
                                            <div className="flex-row btn_content">
                                                <span
                                                    className="loading">{t('createNewOfferPage.photos.download')}</span>
                                                <img src={clip} alt="clip" />
                                            </div>
                                            <input
                                                id="input-text"
                                                onChange={uploadMultipleFiles}
                                                name="photos"
                                                className="input_files"
                                                accept="image/png, image/jpg, image/jpeg"
                                                multiple
                                                type="file"
                                            />
                                        </div>
                                    </>
                                    :
                                    <div className="upload_btn">
                                        <div className="flex-row btn_content">
                                            <span className="loading">{t('createNewOfferPage.photos.download')}</span>
                                            <img src={clip} alt="clip" />
                                        </div>
                                        <input
                                            id="input-text"
                                            onChange={uploadMultipleFiles}
                                            name="photos"
                                            className="input_files"
                                            accept="image/png, image/jpg, image/jpeg"
                                            multiple
                                            type="file"
                                        />
                                    </div>)
                                :
                                <ImageList sx={{width: 630, height: 250}} cols={5} rowHeight={100}
                                           className="m-0 rounded-3 overflow-hidden">
                                    {finishPhotos.map((preview: string, index: number) => (
                                        <div className="image_list_item" key={index}>
                                            <PhotoManager photo={preview} isUpdateOffer={true}
                                                          offerWithIdPhotos={offerWithId.photos} offerId={id} />
                                        </div>
                                    ))}
                                </ImageList>}
                        </div>
                    </div>

                    <div className="white-box-update">
                        <h4 className="mb-4">{t('createNewOfferPage.rent.title')}</h4>
                        <div className="flex-row justify-content-lg-start">
                            <input
                                placeholder={t('createNewOfferPage.rent.price')}
                                defaultValue={offerWithId.price}
                                type="number"
                                id="price"
                                className={
                                    errors.price
                                        ? 'input-prices error-input'
                                        : 'input-prices'
                                }
                                {...register('price', {
                                    required: t('formErrors.required').toString(),
                                    min: {
                                        value: 0,
                                        message: t('formErrors.minLength').toString(),
                                    },
                                })}
                            />
                            <input
                                className="input-type input-cur"
                                {...register('currency')}
                                value="UAH"
                                readOnly={true}
                            />
                        </div>

                        <div>
                            {errors.price ? (
                                <p className="error-message mt-1">
                                    {errors.price.message}
                                </p>
                            ) : (
                                <p></p>
                            )}
                        </div>

                        <div className="">
                            <div className="lbl">{t('createNewOfferPage.rent.payments.title')}</div>

                            <div className="facility jcsa btn_ml">
                                <label className="control" htmlFor="included">
                                    <input
                                        type="radio"
                                        id="included"
                                        checked={communalIncludedCheckBoxes[0]}
                                        onClick={() => handleCommunalCheckboxClick(0)}
                                        {...register('communalIncluded', {
                                            value: 'true',
                                        })}
                                    />
                                    {errors.communalIncluded ? (
                                        <p className="error-message">
                                            {errors.communalIncluded.message}
                                        </p>
                                    ) : (
                                        <p></p>
                                    )}
                                    <span className="content">{t('createNewOfferPage.rent.payments.counters')}</span>
                                </label>

                                <label className="control" htmlFor="not_included">
                                    <input
                                        type="radio"
                                        id="not_included"
                                        checked={communalIncludedCheckBoxes[1]}
                                        onClick={() => handleCommunalCheckboxClick(1)}
                                        {...register('communalIncluded', {
                                            required: t('formErrors.required').toString(),
                                            value: 'false',
                                        })}
                                    />
                                    {errors.communalIncluded ? (
                                        <p className="error-message">
                                            {errors.communalIncluded.message}
                                        </p>
                                    ) : (
                                        <p></p>
                                    )}
                                    <span className="content">{t('createNewOfferPage.rent.payments.included')}</span>
                                </label>
                            </div>

                            <div className="flex-wrapper" />

                            {petDeposit &&
                              <div className="">
                                <div className="lbl">{t('createNewOfferPage.rent.deposit.pet.title')}</div>
                                <input
                                  id="petDeposit"
                                  defaultValue={offerWithId.petDeposit}
                                  type="number"
                                  className={
                                      errors.petDeposit
                                          ? 'deposit error-input'
                                          : 'deposit'
                                  }
                                  placeholder={t('createNewOfferPage.rent.price')}
                                  {...register('petDeposit', {
                                      required: t('formErrors.required').toString(),
                                      min: {
                                          value: 0,
                                          message: t('formErrors.minLength').toString(),
                                      },
                                  })}
                                />
                                  {errors.petDeposit ? (
                                      <p className="error-message">{errors.petDeposit.message}</p>
                                  ) : (
                                      <p></p>
                                  )}
                              </div>}

                            <div className="">
                                <div className="lbl">{t('createNewOfferPage.rent.deposit.security.title')}</div>
                                <input
                                    type="number"
                                    defaultValue={offerWithId.securityDeposit}
                                    className={
                                        errors.securityDeposit
                                            ? 'deposit error-input'
                                            : 'deposit'
                                    }
                                    placeholder={t('createNewOfferPage.rent.price')}
                                    {...register('securityDeposit', {
                                        min: {
                                            value: 0,
                                            message: t('formErrors.minLength').toString(),
                                        },
                                    })}
                                />
                                {errors.securityDeposit ? (
                                    <p className="error-message">
                                        {errors.securityDeposit.message}
                                    </p>
                                ) : (
                                    <p className="mb-0"></p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form_button_update">
                    <LoaderButtonFormService buttonContent={t('updateOfferPage.submitBtn')}
                                             buttonClassName={'update_offer_button'}
                                             submit={handleSubmit(submit)}
                                             color={LoaderColor.White}
                    />
                </div>
            </form>
        </div>
    );
};

export default UpdateOffer;