import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {GetGeolocation} from '../../models/generalTypes';
import offersSelectors from '../../features/offers/offersSelectors';
import offersOperations from '../../features/offers/offersOperations';
import geolocationSelectors from '../../features/geolocation/geolocationSelectors';
import {useForm} from 'react-hook-form';
import {ImageList} from '@mui/material';
import PhotoManager from './photos/PhotoManager';
import AddressBlock from './address/AddressBlock';
import ApartmentBlock from './apartment/ApartmentBlock';
import FacilityBlock from './falcility/FacilityBlock';
import {useTranslation} from 'react-i18next';
import LoaderButtonFormService from '../../common/Button/loaderButtonFormService/LoaderButtonFormService';
import BuildingBlock from './building/BuildingBlock';
import useWindowSize from '../../hooks/useWindowSize';
import offersSlice from '../../features/offers/offersSlice';
import DatePicker from 'react-datepicker';

import LoaderColor from '../../common/Button/enum/LoaderColor';
import BlockId from './enums/BlockId';
import FieldId from './enums/FieldId';

import plug from '../../assets/photos/plug.png';
import clip from '../../assets/icons/clip.svg';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import './AddNewOffer.scss';

const AddNewOffer: React.FC = () => {

    const todayDate = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(todayDate.getDate() + 1);

    const [startDate, setStart] = useState(tomorrow);

    const afterYear = todayDate.getUTCFullYear() + 1;

    todayDate.setFullYear(afterYear);

    const isoDayAfterYear = todayDate.toISOString();

    const ref = React.useRef<HTMLDivElement>(null);

    const [switchMenu, setSwitchMenu] = useState(false);

    const [petDeposit, setPetDeposit] = useState(false);

    const dispatch = useDispatch();

    const {t} = useTranslation();

    const getCityWhenCreate = useSelector(offersSelectors.getCityWhenCreate);

    const {isScreenResolutionMobile} = useWindowSize();

    const {
        register,
        setValue,
        handleSubmit,
        formState: {errors},
        watch,
        clearErrors,
    } = useForm({
        shouldFocusError: false,
    });

    const inputAddress: Array<GetGeolocation> = useSelector(
        geolocationSelectors.getAddress,
    );

    const [geo, setGeolocation] = useState('');

    const createdOfferFacility = useSelector(
        offersSelectors.createdOfferFacility,
    ) as Array<string>;

    const isFormSubmitted: boolean = useSelector(offersSelectors.isFormSubmitted);

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            scrollToError();
        }
    }, [errors, isFormSubmitted]);

    useEffect(() => {
        setValue('communalIncluded', 'true');
        setValue('animalPermission', 'false');
    }, [setValue]);

    useEffect(() => {
        const animalPermission = watch('animalPermission');
        if (animalPermission === 'true') {
            setPetDeposit(true);
        } else {
            setPetDeposit(false);
        }
    }, [watch('animalPermission')]);

    const getLocationBlockId = (fieldId: string) => {
        switch (fieldId) {
            case FieldId.Address:
                return BlockId.Address;
            case FieldId.ConstructionYear:
            case FieldId.Floor:
            case FieldId.Floors:
                return BlockId.Building;
            case FieldId.Rooms:
            case FieldId.TotalArea:
                return BlockId.Flat;
            case FieldId.Facility:
                return BlockId.Facilities;
            case FieldId.AnimalPermission:
                return BlockId.Rules;
            case FieldId.Title:
            case FieldId.Description:
                return BlockId.Description;
            case FieldId.Price:
            case FieldId.PetDeposit:
                return BlockId.Rent;
            default:
                return '';
        }
    };

    const scrollToError = () => {

        const errorFields = Object.keys(errors);

        const fieldOrder: Record<string, number> = {
            [FieldId.Address]: 1,
            [FieldId.ConstructionYear]: 2,
            [FieldId.Floor]: 3,
            [FieldId.Floors]: 4,
            [FieldId.Rooms]: 5,
            [FieldId.TotalArea]: 6,
            [FieldId.Facility]: 7,
            [FieldId.AnimalPermission]: 8,
            [FieldId.Title]: 9,
            [FieldId.Description]: 10,
            [FieldId.Price]: 11,
            [FieldId.PetDeposit]: 12,
        };

        const sortedErrorFields = errorFields.sort((a, b) => {
            const orderA = fieldOrder[a] || Number.MAX_SAFE_INTEGER;
            const orderB = fieldOrder[b] || Number.MAX_SAFE_INTEGER;
            return orderA - orderB;
        });

        if (sortedErrorFields.length > 0) {
            const firstErrorField = sortedErrorFields[0];
            const element = document.getElementById(getLocationBlockId(firstErrorField));
            if (element) {
                element.scrollIntoView({behavior: 'smooth'});
            }
        }
    };

    const offerPhotos = useSelector(offersSelectors.photosWhenCreateOffer);

    const [finishPhotos, setFinishPhotos] = useState([] as any);

    const submit = async (formValues: any) => {

        const addressString = inputAddress[0].formattedAddress;

        const updValues = {
            animalPermission: formValues.animalPermission === 'true',
            availableFrom: startDate.toISOString(),
            communalIncluded: formValues.communalIncluded === 'true',
            constructionYear: formValues.constructionYear,
            currency: formValues.currency,
            description: formValues.description,
            expiresOn: isoDayAfterYear,
            facilities: createdOfferFacility,
            floor: formValues.floor,
            floors: formValues.floors,
            geolocation: {
                countryCode: 'UA',
                city: getCityWhenCreate.name,
                address: addressString,
                latitude: inputAddress[0].latitude,
                longitude: inputAddress[0].longitude,
            },
            kitchenArea: formValues.kitchenArea,
            livingArea: formValues.livingArea,
            petDeposit: formValues.petDeposit !== '' ? formValues.petDeposit : 0,
            price: formValues.price,
            rooms: formValues.rooms[0],
            securityDeposit: formValues.securityDeposit,
            title: formValues.title,
            totalArea: formValues.totalArea,
            type: formValues.type,
        };

        await dispatch(offersOperations.createOffer(updValues as any));
    };

    useEffect(() => {
        const hasPhotos = finishPhotos.filter((image: string) => image !== plug).length > 0;

        if (hasPhotos) {
            clearErrors('photos');
        }
    }, [finishPhotos, clearErrors]);

    const getPhotos = useCallback(() => {

        const photosAdded = [];

        for (let i = 0; i < 10; i++) {
            if (offerPhotos[i] !== undefined) {
                const photos = offerPhotos[i];
                for (let j = 0; j < photos.length && photosAdded.length < 10; j++) {
                    photosAdded.push(photos[j]);
                }
            }
        }

        while (photosAdded.length < 10) {
            photosAdded.push(plug);
        }

        return photosAdded;
    }, [offerPhotos]);

    useEffect(() => {
        setFinishPhotos(getPhotos());
    }, [offerPhotos]);

    const uploadMultipleFiles = (e: any) => {
        const files = Array.from(e.target.files);
        dispatch(offersSlice.actions.setPhotosFilesWhenCreateOffer(files));
        const previews: string[] = [];
        for (let i = 0; i < files.length; i++) {
            const file: any = files[i];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                previews.push(reader.result as string);
                if (previews.length === files.length) {
                    dispatch(offersSlice.actions.setPhotosWhenCreateOffer(previews));
                }
            };
        }
    };

    const [characterCountTitle, setCharacterCountTitle] = useState(0);
    const [characterCountDescription, setCharacterCountDescription] = useState(0);

    const handleCharacterChange = (e: ChangeEvent<HTMLInputElement>) => {
        const inputText = e.target.value;
        if (e.target.tagName === 'INPUT') {
            setCharacterCountTitle(inputText.length);
        } else setCharacterCountDescription(inputText.length);
    };

    const validatePhotos = () => {
        const hasPhotos = finishPhotos.filter((image: any) => image !== plug).length > 0;

        if (!hasPhotos) {
            return t('formErrors.photoError');
        }
        return true;
    };

    return (
        <div className={isScreenResolutionMobile ? 'container p-0 mt-3 flex_menu' : 'flex_menu'}>

            <form onSubmit={handleSubmit(submit)}>

                <div>
                    <section
                        id="address"
                        className="bg-white h-50 blocks rounded-3 mb-4 p-4"
                        aria-invalid={errors.address ? 'true' : 'false'}
                    >
                        <div ref={ref}>
                            <AddressBlock geo={geo} setGeolocation={setGeolocation} register={register}
                                          errors={errors} />
                        </div>
                    </section>

                    <section id="building" className="bg-white h-50 blocks rounded-3 mb-4 p-4">
                        <BuildingBlock register={register} errors={errors} />
                    </section>

                    <section id="flat" className="bg-white h-50 blocks rounded-3 mb-4 p-4">
                        <ApartmentBlock register={register} errors={errors} />
                    </section>

                    <section id="facilities" className="bg-white h-50 blocks rounded-3 mb-4 p-4 pb-2">
                        <FacilityBlock register={register} errors={errors} />
                    </section>

                    <section id="rules" className="bg-white h-50 blocks rounded-3 mb-4 p-4">

                        <h4 className="mb-4">{t('createNewOfferPage.rules.title')}</h4>

                        <div className={isScreenResolutionMobile ? 'flex-column' : 'area-row'}>
                            <div className="animal_block">{t('createNewOfferPage.rules.pets.name')}</div>
                            <div className={isScreenResolutionMobile ? 'flex_menu' : 'area-row'}>
                                <label className="control" htmlFor="permission1">
                                    <input
                                        type="radio"
                                        id="permission1"
                                        checked={watch('animalPermission') === 'true'}
                                        value="true"
                                        {...register('animalPermission', {
                                            required: t('formErrors.required').toString(),
                                        })}
                                    />
                                    <span className="content">{t('createNewOfferPage.rules.pets.yes')}</span>
                                </label>

                                <label className="control" htmlFor="permission2">
                                    <input
                                        type="radio"
                                        id="permission2"
                                        checked={watch('animalPermission') === 'false'}
                                        value="false"
                                        {...register('animalPermission', {
                                            required: t('formErrors.required').toString(),
                                        })}
                                    />
                                    <span className="content">{t('createNewOfferPage.rules.pets.no')}</span>
                                </label>
                            </div>
                        </div>
                        <div className={isScreenResolutionMobile ? 'flex-column' : 'area-row mt-3'}>
                            <div className="date_new_offer">{t('createNewOfferPage.date.description')}</div>
                            <DatePicker
                                autoComplete="off"
                                name="availableFrom"
                                className="calendar"
                                selected={startDate}
                                onChange={(val: any) => {
                                    setStart(val);
                                    setValue('availableFrom', val);
                                }}
                                dateFormat="dd.MM.yyyy"
                            />
                        </div>
                    </section>

                    <section id="description" className="bg-white h-50 blocks rounded-3 mb-4 p-4 pb-2">

                        <h4 className="mb-3">{t('createNewOfferPage.description.title')}</h4>

                        <div className={isScreenResolutionMobile ? 'flex-column' : 'flex-row'}>
                            <div className="space-between">
                                <div
                                    className="lbl-apartment mt-3 mr_title">{t('createNewOfferPage.description.header')}</div>
                                <p className="character_count">{characterCountTitle}/100</p>
                            </div>
                            <input
                                id="title"
                                maxLength={100}
                                className={
                                    errors.title
                                        ? 'error'
                                        : 'address'
                                }
                                type="text"
                                {...register('title', {
                                    required: t('formErrors.required').toString(),
                                    onChange: handleCharacterChange,
                                })}
                            />
                        </div>

                        <div>
                            {errors.title ? (
                                <p className="error-message mt-1 ml">{errors.title.message}</p>
                            ) : (
                                <p></p>
                            )}
                        </div>

                        <div className={isScreenResolutionMobile ? 'flex-column' : 'flex_column_new_offer'}>
                            <div className="space-between">
                                <div className="descriptions">{t('createNewOfferPage.description.description')}</div>
                                <p className="character_count">{characterCountDescription}/1000</p>
                            </div>
                            <textarea
                                maxLength={1000}
                                placeholder={t('createNewOfferPage.description.placeholder')}
                                className={
                                    errors.description
                                        ? 'error-input_description'
                                        : 'input-text-description'
                                }
                                {...register('description', {
                                    required: t('formErrors.required').toString(),
                                    onChange: handleCharacterChange,
                                })}
                            />
                        </div>

                        <div>
                            {errors.description ? (
                                <p className="error-message mt-1 ml">{errors.description.message}</p>
                            ) : (
                                <p></p>
                            )}
                        </div>
                    </section>

                    <section id="photo" className="bg-white blocks rounded-3 mb-4 p-4">

                        <h4>{t('createNewOfferPage.photos.title')}</h4>

                        <p className="recommendation">
                            {t('createNewOfferPage.photos.description')}
                        </p>

                        <div className="photo_section">

                            {isScreenResolutionMobile ?
                                (finishPhotos.filter((image: string) => image !== plug).length !== 0 ?
                                        <>
                                            <ImageList sx={{width: '100%', height: 'auto'}} cols={2} rowHeight={200}
                                                       className="m-0 rounded-3">
                                                {finishPhotos.filter((image: string) => image !== plug).map((preview: string, index: number) => (
                                                    <div className="image_list_item" key={index}>
                                                        <PhotoManager photo={preview} index={index} register={register}
                                                                      finishPhotos={finishPhotos} />
                                                    </div>
                                                ))}
                                            </ImageList>
                                            <div className="upload_btn">
                                                <div className="flex_row_new_offer btn_content">
                                                    <span
                                                        className="loading">{t('createNewOfferPage.photos.download')}
                                                    </span>
                                                    <img src={clip} alt="clip" />
                                                </div>
                                                <input
                                                    {...register('photos', {
                                                        validate: validatePhotos,
                                                    })}
                                                    id="input-text"
                                                    onChange={uploadMultipleFiles}
                                                    name="photos"
                                                    className="input_files"
                                                    accept="image/png, image/jpg, image/jpeg"
                                                    multiple
                                                    type="file"
                                                />
                                            </div>
                                        </>
                                        :
                                        <div className="upload_btn">
                                            <div className="flex_row_new_offer btn_content">
                                                <span
                                                    className="loading">{t('createNewOfferPage.photos.download')}</span>
                                                <img src={clip} alt="clip" />
                                            </div>
                                            <input
                                                {...register('photos', {
                                                    validate: validatePhotos,
                                                })}
                                                id="input-text"
                                                onChange={uploadMultipleFiles}
                                                name="photos"
                                                className="input_files"
                                                accept="image/png, image/jpg, image/jpeg"
                                                multiple
                                                type="file"
                                            />
                                        </div>
                                )
                                :
                                <ImageList sx={{width: '100%', height: 'auto'}} cols={5} rowHeight={100}
                                           className="m-0 rounded-3 overflow-hidden">
                                    {finishPhotos.map((preview: string, index: number) => (
                                        <div className="image_list_item" key={index}>
                                            <PhotoManager photo={preview} register={register}
                                                          finishPhotos={finishPhotos} />
                                        </div>
                                    ))}
                                </ImageList>}
                            <div>
                                {errors.photos ? (
                                    <p className="error-message mt-1">
                                        {errors.photos.message}
                                    </p>
                                ) : (
                                    <p></p>
                                )}
                            </div>
                        </div>
                    </section>

                    <section id="rent" className="bg-white h-50 blocks rounded-3 mb-4 p-4">

                        <h4 className="mb-4">{t('createNewOfferPage.rent.title')}</h4>

                        <div className="flex_row_new_offer">
                            <input
                                placeholder={t('createNewOfferPage.rent.price')}
                                type="number"
                                id="price"
                                className={
                                    errors.price
                                        ? 'error-input_new'
                                        : 'input-prices'
                                }
                                {...register('price', {
                                    required: t('formErrors.required').toString(),
                                    min: {
                                        value: 0,
                                        message: t('formErrors.minLength').toString(),
                                    },
                                })}
                            />
                            <input
                                className={errors.price ? 'input-type input-cur-error' : 'input-type input-cur'}
                                {...register('currency')}
                                value="UAH"
                                readOnly={true}
                            />
                        </div>

                        <div>
                            {errors.price ? (
                                <p className="error-message mt-1">
                                    {errors.price.message}
                                </p>
                            ) : (
                                <p></p>
                            )}
                        </div>

                        <div className="">
                            <div className="lbl">{t('createNewOfferPage.rent.payments.title')}</div>

                            <div className="facility jcsa btn_ml">
                                <label className="control" htmlFor="included">
                                    <input
                                        value="true"
                                        type="radio"
                                        id="included"
                                        checked={watch('communalIncluded') === 'true'}
                                        {...register('communalIncluded', {})}
                                    />
                                    {errors.communalIncluded ? (
                                        <p className="error-message">
                                            {errors.communalIncluded.message}
                                        </p>
                                    ) : (
                                        <p></p>
                                    )}
                                    <span className="content">{t('createNewOfferPage.rent.payments.counters')}</span>
                                </label>

                                <label className="control" htmlFor="not_included">
                                    <input
                                        value="false"
                                        type="radio"
                                        id="not_included"
                                        checked={watch('communalIncluded') === 'false'}
                                        {...register('communalIncluded', {
                                            required: t('formErrors.required').toString(),
                                        })}
                                    />
                                    {errors.communalIncluded ? (
                                        <p className="error-message">
                                            {errors.communalIncluded.message}
                                        </p>
                                    ) : (
                                        <p></p>
                                    )}
                                    <span className="content">{t('createNewOfferPage.rent.payments.included')}</span>
                                </label>
                            </div>

                            <div className="flex-wrapper" />

                            {petDeposit &&
                              <div className="">
                                <div className="lbl">{t('createNewOfferPage.rent.deposit.pet.title')}</div>
                                <input
                                  id="petDeposit"
                                  type="number"
                                  className={
                                      errors.petDeposit
                                          ? 'deposit error-input'
                                          : 'deposit'
                                  }
                                  placeholder={t('createNewOfferPage.rent.price')}
                                  {...register('petDeposit', {
                                      required: t('formErrors.required').toString(),
                                      min: {
                                          value: 0,
                                          message: t('formErrors.minLength').toString(),
                                      },
                                  })}
                                />
                                  {errors.petDeposit ? (
                                      <p className="error-message">{errors.petDeposit.message}</p>
                                  ) : (
                                      <p></p>
                                  )}
                              </div>}

                            <div className="">
                                <div className="lbl">{t('createNewOfferPage.rent.deposit.security.title')}</div>
                                <input
                                    type="number"
                                    className={
                                        errors.securityDeposit
                                            ? 'deposit error-input'
                                            : 'deposit'
                                    }
                                    placeholder={t('createNewOfferPage.rent.price')}
                                    {...register('securityDeposit', {
                                        min: {
                                            value: 0,
                                            message: t('formErrors.minLength').toString(),
                                        },
                                    })}
                                />
                                {errors.securityDeposit ? (
                                    <p className="error-message">
                                        {errors.securityDeposit.message}
                                    </p>
                                ) : (
                                    <p className="mb-0"></p>
                                )}
                            </div>
                        </div>
                    </section>
                </div>

                <div className="form_button" onClick={() => {
                    dispatch(offersSlice.actions.setFormSubmitted());
                    setSwitchMenu(true);
                }}>
                    <LoaderButtonFormService buttonContent={t('createNewOfferPage.btn')}
                                             buttonClassName={'add_new_offer_button'}
                                             submit={handleSubmit(submit)}
                                             color={LoaderColor.White}
                    />
                </div>
            </form>
            {/*{!screenResolutionLessMobile && <nav className="menu">*/}
            {/*    <NavigationMenu errors={errors} switchMenu={switchMenu} geo={geo} permission={animalPermission}/>*/}
            {/*</nav>}*/}
        </div>
    );
};

export default AddNewOffer;