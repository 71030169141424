import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import notificationOperations from '../../features/notification/notificationOperations';
import notificationSelectors from '../../features/notification/notificationSelectors';
import notificationSlice, {NotificationType} from '../../features/notification/notificationSlice';
import LoaderButtonService from '../../common/Button/loaderButtonService/LoaderButtonService';
import LoaderColor from '../../common/Button/enum/LoaderColor';
import Notification from './notification/Notification';
import {useTranslation} from 'react-i18next';
import Select, {components} from 'react-select';
import useWindowSize from '../../hooks/useWindowSize';
import {ThreeDots} from 'react-loader-spinner';

import caretDown from '../Acceptances/images/caretDown.svg';

import notificationPageStyles from './NotificationPage.module.scss';

export const enum NotificationSort {
    Date = 'createdOn,desc',
    ReadDesc = 'read,desc',
    ReadAsc = 'read,asc'
}

export type FilterSettingsType = {
    readSort: string;
    dateSort: string;
    read: string;
    page: number;
}

const NotificationPage = () => {

    const dispatch = useDispatch();

    const {t} = useTranslation();

    enum SelectOption {
        All = 'notification.page.selectOption.all',
        Unread = 'notification.page.selectOption.unread',
        Read = 'notification.page.selectOption.read'
    }

    enum SelectItem {
        All = 'all',
        Unread = 'unread',
        Read = 'read'
    }

    const unreadCountNotifications = useSelector(notificationSelectors.getUnreadCountNotifications);

    const notifications: Array<NotificationType> = useSelector(notificationSelectors.getNotifications.selectAll);

    const notificationCount = useSelector(notificationSelectors.getNotificationCount);

    const totalPages = useSelector(notificationSelectors.getTotalPages);

    const [page, setPage] = useState(1);

    const {isScreenResolutionMobile} = useWindowSize();

    const isLoading: boolean = useSelector(notificationSelectors.isLoading);

    useEffect(() => {
        dispatch(notificationOperations.getNotifications({
            read: '',
            page: 0,
            size: 5,
            dateSort: '',
            readSort: NotificationSort.ReadAsc,
        }));
        dispatch(notificationOperations.getUnreadCountNotifications());
    }, []);

    const [item, setItem] = useState(t(SelectOption.All));

    const selectOptions: Array<any> = [{value: SelectItem.All, label: t(SelectOption.All)}, {
        value: SelectItem.Unread,
        label: t(SelectOption.Unread),
    }, {value: SelectItem.Read, label: t(SelectOption.Read)}];

    const DropdownIndicator = (props: any) => {
        return (
            <components.DropdownIndicator {...props}>
                <img src={caretDown} alt="caretDown" />
            </components.DropdownIndicator>
        );
    };

    const getStylesMenu = () => {
        return isScreenResolutionMobile ? (provided: any) => ({
                ...provided,
                borderRadius: '25px',
                paddingTop: '5px',
                overflow: 'hidden',
                width: '210px',
                height: '108px',
                zIndex: 3,
                right: '10px',
                top: '25px',
            })
            :
            (provided: any) => ({
                ...provided,
                borderRadius: '25px',
                paddingTop: '5px',
                overflow: 'hidden',
                width: '210px',
                height: '108px',
            });
    };

    const customStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
            color: '#828282',
            backgroundColor: '#fff',
            outline: 'none',
            border: 'none',
            cursor: 'pointer',
            boxShadow: 'none',
            padding: '5px 10px 5px 20px',
            '&:hover': {
                color: '#253040',
                backgroundColor: '#e0e0e0',
            },
            fontSize: '14px',
        }),
        control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            height: '14px',
            marginTop: '-7px',
            cursor: 'pointer',
            fontSize: '14px',
            color: '#253040',
            border: 'none',
            boxShadow: 'none',
            '&:hover': {
                outline: 'none',
                border: 'none',
            },
            '&:active': {
                border: 'none',
                outline: 'none',
            },
            '&:focus': {
                border: 'none',
                outline: 'none',
            },
        }),
        indicatorSeparator: (provided: any) => ({
            ...provided,
            display: 'none',
        }),
        dropdownIndicator: (provided: any) => ({
            ...provided,
            marginBottom: '3px',
            padding: '0',
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: '#253040',
            fontSize: '14px',
            fontWeight: 500,
        }),
        menu: getStylesMenu(),
    };

    const [filterSettings, setFilterSettings] = useState({
        dateSort: '',
        readSort: NotificationSort.ReadAsc,
        read: '',
        page: 0,
    } as FilterSettingsType);

    useEffect(() => {
        return () => {
            dispatch(notificationSlice.actions.clearNotifications(notifications));
            dispatch(notificationSlice.actions.clearUnreadCount(unreadCountNotifications));
        };
    }, []);


    const handleChangeSelect = async (e: any) => {
        setItem(e);
        if (e.value === SelectItem.All) {
            await dispatch(notificationSlice.actions.clearNotifications(notifications));
            dispatch(notificationOperations.getNotifications({
                size: 5,
                page: 0,
                read: '',
                dateSort: NotificationSort.Date,
                readSort: NotificationSort.ReadAsc,
            }));
            setPage(1);
            setFilterSettings({
                dateSort: NotificationSort.Date,
                readSort: NotificationSort.ReadAsc,
                read: '',
                page: page,
            });
        } else if (e.value === SelectItem.Read) {
            await dispatch(notificationSlice.actions.clearNotifications(notifications));
            dispatch(notificationOperations.getNotifications({
                size: 5,
                page: 0,
                read: 'true',
                dateSort: NotificationSort.Date,
            }));
            setPage(1);
            setFilterSettings({
                dateSort: NotificationSort.Date,
                readSort: NotificationSort.ReadDesc,
                read: 'true',
                page: page,
            });
        } else if (e.value === SelectItem.Unread) {
            await dispatch(notificationSlice.actions.clearNotifications(notifications));
            dispatch(notificationOperations.getNotifications({
                size: 5,
                page: 0,
                read: 'false',
                dateSort: NotificationSort.Date,
            }));
            setPage(1);
            setFilterSettings({
                dateSort: NotificationSort.Date,
                readSort: NotificationSort.ReadDesc,
                read: 'false',
                page: page,
            });
        }
    };

    return (
        <div className={notificationPageStyles.main}>

            <div className={notificationPageStyles.notification_flex_end}>

                <p className={notificationPageStyles.unread_count}>
                    {unreadCountNotifications} {t('notification.page.unreadCount')}
                </p>

                <Select name="select"
                        isSearchable={false}
                        placeholder={t('notification.page.placeholder')}
                        value={item}
                        onChange={handleChangeSelect}
                        options={selectOptions}
                        components={{DropdownIndicator}}
                        styles={customStyles} />
            </div>

            <div className={notificationPageStyles.scroll}>

                {isLoading && notifications.length === 0 &&
                  <div className="center">
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color={LoaderColor.Green}
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      visible={true}
                    />
                  </div>}

                {notifications.length !== 0 &&
                    notifications.map(notification =>
                        <Notification notification={notification} key={notification.id} page={page - 1} />,
                    )}

                {notifications.length !== 0 && notifications.length < notificationCount && totalPages > 1 &&
                  <LoaderButtonService
                    query={notificationOperations.getNotifications({
                        page: page,
                        dateSort: filterSettings.dateSort,
                        readSort: filterSettings.readSort,
                        size: 5,
                        read: filterSettings.read,
                    })}
                    buttonContent={t('notification.page.moreBtn')}
                    buttonClassName={'btn_more'}
                    page={page}
                    setPage={setPage}
                    color={LoaderColor.Green}
                  />
                }

            </div>
        </div>
    );
};

export default NotificationPage;