import {createSlice, createEntityAdapter} from '@reduxjs/toolkit';
import acceptanceOperations from './acceptanceOperations';
import {
    AcceptanceCreatorType,
    AcceptanceType,
    OffersAcceptancesCountType,
} from '../../models/generalTypes';
import {toast} from 'react-toastify';
import i18n from 'i18next';
import {notificationsAdapter} from '../notification/notificationSlice';

export const acceptancesAdapter = createEntityAdapter<AcceptanceType>();
export const acceptanceCreatorAdapter = createEntityAdapter<AcceptanceCreatorType>();
export const offersAcceptancesCountAdapter = createEntityAdapter<OffersAcceptancesCountType>({
    selectId: (count) => count.offerId,
});
export const userAcceptancesAdapter = createEntityAdapter<AcceptanceType>();
export const updateAcceptanceAdapter = createEntityAdapter<AcceptanceType>();

interface InitialState {
    acceptanceById: any;
    createdAcceptance: any;
    isLoading: boolean;
    updatedAcceptance: any;
    offerAcceptances: any;
    userAcceptances: any;
    offersAcceptancesCount: any;
    updatedAcceptanceStatus: any;
    acceptanceCreator: any;
    totalPagesOfferAcceptances: any;
    totalElementsUserAcceptances: any;
    pageElementsCountUserAcceptances: any;
    totalElementsOfferAcceptances: any;
}

const initialState: InitialState = {
    createdAcceptance: [],
    updatedAcceptance: updateAcceptanceAdapter.getInitialState(),
    offerAcceptances: acceptancesAdapter.getInitialState(),
    userAcceptances: userAcceptancesAdapter.getInitialState(),
    isLoading: false,
    offersAcceptancesCount: offersAcceptancesCountAdapter.getInitialState(),
    updatedAcceptanceStatus: [],
    acceptanceCreator: acceptancesAdapter.getInitialState(),
    totalPagesOfferAcceptances: null,
    totalElementsUserAcceptances: null,
    pageElementsCountUserAcceptances: null,
    totalElementsOfferAcceptances: null,
    acceptanceById: [],
};

const acceptanceSlice = createSlice({
    name: 'acceptance',
    initialState,
    reducers: {
        clearOfferAcceptances: (state, {payload}) => {
            acceptancesAdapter.removeAll(state.offerAcceptances);
        },
        clearAcceptanceCreator: (state, {payload}) => {
            acceptancesAdapter.removeAll(state.acceptanceCreator);
        },
        updateOfferAcceptancesCount: (state, {payload}) => {
            offersAcceptancesCountAdapter.updateOne(state.offersAcceptancesCount, payload);
        },
        clearUserAcceptances: (state, {payload}) => {
            userAcceptancesAdapter.removeAll(state.userAcceptances);
        },
        clearTotalElementsUserAcceptances: (state, {payload}) => {
            state.totalElementsUserAcceptances = null;
        },
        removeOne: (state, {payload}) => {
            userAcceptancesAdapter.removeOne(state.userAcceptances, payload);
        },
    },
    extraReducers: builder => {
        builder.addCase(
            acceptanceOperations.createAcceptance.fulfilled,
            (state, action) => {
                state.createdAcceptance = action.payload;
                toast.success(i18n.t('notification.acceptanceCreated'));
            },
        );
        builder.addCase(
            acceptanceOperations.getOfferAcceptances.fulfilled,
            (state, action) => {
                acceptancesAdapter.setMany(state.offerAcceptances, action.payload.elements);
                state.totalPagesOfferAcceptances = action.payload.totalPages;
                state.totalElementsOfferAcceptances = action.payload.totalElements;
            },
        );
        builder.addCase(
            acceptanceOperations.getUserAcceptances.pending,
            (state, action) => {
                state.isLoading = true;
            },
        );
        builder.addCase(
            acceptanceOperations.getUserAcceptances.rejected,
            (state, action) => {
                state.isLoading = false;
            },
        );
        builder.addCase(
            acceptanceOperations.getUserAcceptances.fulfilled,
            (state, {payload}) => {
                state.isLoading = false;
                if (payload) {
                    const { isShowMore, data } = payload;
                    state.totalElementsUserAcceptances = data.totalElements;
                    state.pageElementsCountUserAcceptances = data.totalPages;
                    if (isShowMore) {
                        notificationsAdapter.setMany(state.userAcceptances, data.elements);
                    } else {
                        notificationsAdapter.setAll(state.userAcceptances, data.elements);
                    }
                }
            },
        );
        builder.addCase(
            acceptanceOperations.getAcceptanceById.fulfilled,
            (state, action) => {
                acceptancesAdapter.setOne(state.offerAcceptances, action.payload.elements);
            },
        );
        builder.addCase(
            acceptanceOperations.getOffersAcceptancesCount.fulfilled,
            (state, action) => {
                offersAcceptancesCountAdapter.setMany(state.offersAcceptancesCount, action.payload);
            },
        );
        builder.addCase(
            acceptanceOperations.updateAcceptanceStatus.fulfilled,
            (state, action) => {
                state.updatedAcceptanceStatus = action.payload.status;
            },
        );
        builder.addCase(
            acceptanceOperations.getAcceptanceCreator.fulfilled,
            (state, action) => {
                acceptanceCreatorAdapter.setOne(state.acceptanceCreator, action.payload);
            },
        );
        builder.addCase(
            acceptanceOperations.updateAcceptance.fulfilled,
            (state, {payload}) => {
                updateAcceptanceAdapter.setOne(state.updatedAcceptance, payload);
                const {id, ...changes} = payload;
                userAcceptancesAdapter.updateOne(state.userAcceptances, {id, changes});
            },
        );
    },
});

export default acceptanceSlice;
